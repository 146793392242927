<template>
  <ZListGroup class="predictions">
    <ZListGroupItem
      v-for="location in predictions"
      :key="location.place_id"
      button
      @click="emit('set-place', location)"
    >
      {{ location.structured_formatting.main_text }}
      <small>{{ location.structured_formatting.secondary_text }}</small>
    </ZListGroupItem>
  </ZListGroup>
</template>

<script setup lang="ts">
defineProps<{
  predictions: google.maps.places.AutocompletePrediction[]
}>()

const emit = defineEmits<{
  'set-place': [location: google.maps.places.AutocompletePrediction]
}>()
</script>

<style lang="scss" scoped>
.predictions {
  z-index: 1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .zlist-group-item-action {
    border-bottom: 0;
    border-top: 0;
    color: getColor('primary-500');

    small {
      display: block;
      color: getColor('primary-400');
    }
  }
}
</style>
