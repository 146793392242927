<template>
  <tr :class="[{ total, discount, 'is-clickable': isClickable }, nestedClass]">
    <td :data-testid="labelDataTestid">
      <slot name="label">
        {{ label }}
        <ZTooltipIcon
          v-if="tooltip"
          :content="tooltip"
          :title="label"
          icon-size="sm"
          class="ml-1"
        />
      </slot>
    </td>
    <td :data-testid="valueDataTestid">
      <slot name="value">
        {{ value }}
      </slot>
    </td>
  </tr>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  label?: string
  value?: string
  tooltip?: string
  nested?: number
  total?: boolean
  discount?: boolean
  isClickable?: boolean
  labelDataTestid?: string
  valueDataTestid?: string
}>(), {
  label: '',
  value: '',
  tooltip: '',
  nested: 0,
})

const nestedClass = computed(() => props.nested ? `nested-${props.nested}` : undefined)
</script>

<style lang="scss" scoped>
tr {
  @include caption;

  &.is-clickable {
    cursor: pointer;
  }

  td {
    text-align: left;

    + td {
      text-align: right;
      white-space: nowrap;
    }
  }

  &[class^='nested-'] {
    color: getColor('primary-350');

    &.nested-1 {
      td:first-child {
        padding-left: 1rem;
      }
    }
    &.nested-2 {
      td:first-child {
        padding-left: 2rem;
      }
    }
  }

  &.total {
    @include strong-1;
  }

  &.discount {
    td {
      &:first-child {
        width: 100%;
      }

      &:last-child {
        color: getColor('highlight-500');
        font-weight: 600;
      }
    }
  }
}
</style>
