import type { Nullable } from '~/types'
import type { COUNTRY_SHORTS, CURRENCIES } from '~/constants'
import { CONVERSION_RATES } from '~/constants'

type PriceDisplay = {
  value?: Nullable<number>
  countryCode?: COUNTRY_SHORTS
  showCurrencyCode?: boolean
  round?: boolean
  isFinancialNegative?: boolean // TODO: Deprecate this
  internationalPricing?: boolean
  notation?: Intl.NumberFormatOptions['notation']
}

// TODO refactor all usePrice implementation
export default function usePrice() {
  const { t, locale } = useI18n({
    useScope: 'global',
  })

  const { isWebView } = usePlatform()
  const { isLoggedIn } = useAuthentication()
  const { selectedCurrencyCode } = useCurrency()

  const canShowInternationalPricing = computed(() => !isWebView.value && !isLoggedIn.value)

  function priceDisplay({
    value,
    countryCode,
    showCurrencyCode = false,
    round = false,
    isFinancialNegative = false,
    internationalPricing = false,
    notation = 'standard',
  }: PriceDisplay) {
    if (typeof value !== 'number' || isNaN(value)) {
      return ''
    }

    const internalLocale = countryCode ? `${locale.value}-${countryCode}` : locale.value
    const currencyCode = countryCode ? lookupCountryCodeToCurrencyCode(countryCode) : undefined

    const isValueNegative = value < 0

    const formatter = new Intl.NumberFormat(internalLocale, {
      minimumFractionDigits: round ? 0 : 2,
      maximumFractionDigits: round ? 0 : 2,
      notation,
    })

    const amount = Math.abs(value)

    const moneyDisplay = ({
      amount,
      currencyCode,
      showCurrencyCode,
    }: {
      amount: number
      currencyCode?: CURRENCIES
      showCurrencyCode: boolean
    }) => {
      const negativeSign = isValueNegative ? '-' : ''
      const currencyDisplay = showCurrencyCode && currencyCode ? ` ${currencyCode}` : ''
      const moneySymbolDisplay = t('common.moneyDisplay', { amount: formatter.format(amount) })
      const openParenthesis = isFinancialNegative ? '(' : ''
      const closeParenthesis = isFinancialNegative ? ')' : ''

      return `${negativeSign}${openParenthesis}${moneySymbolDisplay}${currencyDisplay}${closeParenthesis}`
    }

    if (internationalPricing && canShowInternationalPricing.value) {
      if (!currencyCode) {
        throw createError('`countryCode` is required for international pricing')
      }

      const convertedCurrencyCode = selectedCurrencyCode.value as CURRENCIES
      const conversionRate = CONVERSION_RATES[currencyCode][convertedCurrencyCode]

      if (conversionRate) {
        const convertedAmount = amount * conversionRate
        return moneyDisplay({
          amount: convertedAmount,
          currencyCode: convertedCurrencyCode,
          showCurrencyCode,
        })
      }
    }

    return moneyDisplay({ amount, currencyCode, showCurrencyCode })
  }

  return { priceDisplay }
}
