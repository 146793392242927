<template>
  <ZButton
    data-testid="ask-owner-a-question"
    outline
    size="md"
    block
    @click="showModal"
  >
    {{ t('cta') }}
  </ZButton>

  <LazyRvRentalAskOwnerQuestionModal
    v-if="showQuestionModal"
    @close="closeModal"
  />
</template>

<script setup lang="ts">
import { messageAppToSignup } from '~/lib/mobileMessages/messageAppToSignup'

const runtimeConfig = useRuntimeConfig()
const {
  rvOwnerFirstName,
  rvOwnerLastName,
} = useRvDetails()

const ownerName = computed(() => formatNameAsUsername(rvOwnerFirstName.value, rvOwnerLastName.value))

const { t } = useI18n()
const localePath = useLocalePath()

const askQuestionAfterSignup = ref(false)
const { isLoggedIn } = useAuthentication()
const { isWebView } = usePlatform()
const showQuestionModal = useShowOwnerQuestionModal()

// Watch auth status and show modal if user is now logged in
watch(isLoggedIn, (value) => {
  if (value && askQuestionAfterSignup.value) {
    askQuestionAfterSignup.value = false
    showModal()
  }
})

// If the user arrives from mobile app with intent to ask a question, show the modal
const { query } = useRoute()
onMounted(() => {
  if (query?.intent === 'EnquiryIntent') {
    showModal()
  }
})

const { showSignup } = useSignup()

const { bookingEstimateParams } = useBookingEstimate()
function showModal() {
  if (isLoggedIn.value) {
    showQuestionModal.value = true
    return
  }

  if (isWebView.value) {
    const query = {
      intent: 'EnquiryIntent',
      StartDate: bookingEstimateParams.value?.dateStart ?? undefined,
      EndDate: bookingEstimateParams.value?.dateEnd ?? undefined,
      Adults: bookingEstimateParams.value?.adults.toString() ?? undefined,
      Children: bookingEstimateParams.value?.children.toString() ?? undefined,
      // Pets: bookingEstimateParams.value?.pets ? '1' : undefined, // Not handled at the moment
      Delivery: bookingEstimateParams.value?.rvUsage !== 'Pickup' ? null : undefined,
    }

    const askQuestionUrl = `${runtimeConfig.public.baseURL}${localePath({ query })}`

    messageAppToSignup({
      successUrl: askQuestionUrl,
      failureUrl: window.location.href,
      cookies: {},
      ownerName: ownerName.value,
      action: 'Enquiry',
    })

    return
  }

  askQuestionAfterSignup.value = true

  showSignup({ intent: 'EnquiryIntent', ownerName: ownerName.value })
}

async function closeModal() {
  showQuestionModal.value = false

  if (query?.intent === 'EnquiryIntent') {
    await navigateTo({ query: { ...query, intent: undefined } })
  }
}
</script>

<i18n lang="json">
{
  "en": {
    "cta": "Ask a question"
  },
  "fr": {
    "cta": "Poser une question"
  }
}
</i18n>
