import { UserManager } from 'oidc-client-ts'

import { COOKIES } from '~/constants'
import type { AUTHENTICATION_INTENT } from '~/constants/authentication'
import { EXTERNAL_AUTHENTICATION_PROVIDERS } from '~/constants/authentication'
import { ONE_HOUR_IN_SECONDS, SIXTY_DAYS_IN_SECONDS } from '~/constants/durations'

import { trackSignup } from '~/lib/tracking'

export default function useExternalAuthentication() {
  const runtimeConfig = useRuntimeConfig()
  const { $captureError, $i18n } = useNuxtApp()

  const { isMobileOrTablet } = useDevice()
  const { routeBaseName } = useBaseName()
  const route = useRoute()

  const { isInCaOrUs, geolocation } = useGeolocation()
  const { authenticate, isLoggedIn, user } = useAuthentication()

  const providerScopeMap = {
    [EXTERNAL_AUTHENTICATION_PROVIDERS.Apple]: 'openid profile email',
    [EXTERNAL_AUTHENTICATION_PROVIDERS.Facebook]: 'email',
    [EXTERNAL_AUTHENTICATION_PROVIDERS.Google]: 'openid profile email',
  }

  const useIsExternalAuthenticationLoading = () => useState('externalAuthenticationLoading', () => false)
  const isExternalAuthenticationLoading = useIsExternalAuthenticationLoading()

  // OIDC - Identity Server Auth Config
  function getProviderConfig(provider: EXTERNAL_AUTHENTICATION_PROVIDERS, additionnalExtraQueryParams = {}) {
    if (!provider || !Object.values(EXTERNAL_AUTHENTICATION_PROVIDERS).includes(provider)) {
      throw new Error('Invalid provider')
    }

    const providerName = provider.toString()
    return {
      authority: runtimeConfig.public.oidc.authorityUrl,
      client_id: runtimeConfig.public.oidc.clientId,
      redirect_uri: `${runtimeConfig.public.baseURL}/callback-oauth?action=signin&provider=${providerName}`,
      post_logout_redirect_uri: `${runtimeConfig.public.baseURL}/callback-oauth?action=signout&provider=${providerName}`,
      response_type: 'code',
      scope: providerScopeMap[provider],
      loadUserInfo: true,
      client_secret: runtimeConfig.public.oidc.clientSecret,
      extraQueryParams: {
        asch: providerName,
        ...additionnalExtraQueryParams,
      },
    }
  }

  async function externalDeauthenticate(provider: EXTERNAL_AUTHENTICATION_PROVIDERS) {
    const additionnalExtraQueryParams = {
      lang: $i18n.locale.value,
    }

    try {
      const providerConfig = getProviderConfig(provider, additionnalExtraQueryParams)

      const userManager = new UserManager(providerConfig)

      await userManager.signoutSilent()
    }
    catch (err: unknown) {
      $captureError(err)
    }
  }

  async function triggerExternalAuth({
    provider,
    isSignupEvent = false,
    intent,
  }: {
    provider: EXTERNAL_AUTHENTICATION_PROVIDERS
    isSignupEvent: boolean
    intent?: AUTHENTICATION_INTENT
  }) {
    if (!provider) {
      return
    }

    const isOwnerSignup = intent === 'ListingIntent'

    // Augment query params with additional data
    const additionnalExtraQueryParams = {
      lang: $i18n.locale.value,
      ownerNewsletter: isOwnerSignup,
      renterNewsletter: !isOwnerSignup,
      signUpAs: isOwnerSignup ? 1 : 2,
      signUpCountry: (isInCaOrUs && geolocation.value?.countryCode) ?? '',
      isSignUp: isSignupEvent,
      platform: isMobileOrTablet ? 'mobile_web' : 'web',
      pageSource: routeBaseName.value,
    }
    try {
      isExternalAuthenticationLoading.value = true
      const providerConfig = getProviderConfig(provider, additionnalExtraQueryParams)

      const userManager = new UserManager(providerConfig)

      useCookie(COOKIES.risIntent, {
        domain: runtimeConfig.public.cookieDomain,
        path: '/',
        maxAge: ONE_HOUR_IN_SECONDS,
      }).value = intent?.toString() || null

      useCookie(COOKIES.risRedirect, {
        domain: runtimeConfig.public.cookieDomain,
        path: '/',
        maxAge: SIXTY_DAYS_IN_SECONDS,
      }).value = route.fullPath

      await userManager.signinRedirect()
    }
    catch (err: unknown) {
      isExternalAuthenticationLoading.value = false
      $captureError(err)
    }
  }

  async function externalAuthenticate({
    provider,
    token,
    isSignup,
  }: {
    provider: string
    token: string
    isSignup?: boolean
  }) {
    await authenticate({ token })

    useCookie(COOKIES.risProvider, {
      domain: runtimeConfig.public.cookieDomain,
      path: '/',
      maxAge: SIXTY_DAYS_IN_SECONDS,
    }).value = provider

    trackExternalAuthentication({
      isSignupEvent: <boolean>isSignup,
      provider: <EXTERNAL_AUTHENTICATION_PROVIDERS>provider,
    })
  }

  function trackExternalAuthentication({
    isSignupEvent,
    provider,
  }: {
    isSignupEvent: boolean
    provider: EXTERNAL_AUTHENTICATION_PROVIDERS
  }) {
    if (!isLoggedIn.value) {
      return
    }

    const userVal = user.value
    const userType = userVal?.UserType?.toLowerCase()

    const providerName = provider.toString()

    if (isSignupEvent) {
      trackSignup({
        type: providerName,
        first_name: userVal?.FirstName as string,
        last_name: userVal?.LastName as string,
        context: {
          groupId: userType,
        },
        email: userVal?.Email as string,
        username: userVal?.Email as string,
      })
    }
  }

  return {
    isExternalAuthenticationLoading,
    triggerExternalAuth,
    getProviderConfig,
    externalAuthenticate,
    externalDeauthenticate,
  }
}
