<template>
  <OverlayDrawer
    :visible="visible"
    :title="t('title')"
    @close="emit('close')"
  >
    <LazySearchFilterMoreFilters
      :mobile-overlay-footer-ref="(mobileOverlayFooterRef as Object)"
      @close="emit('close')"
    />
    <template #footer>
      <div
        ref="mobileOverlayFooterRef"
        class="w-100"
      />
    </template>
  </OverlayDrawer>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  visible?: boolean
}>(), {
  // TODO: remove once https://github.com/vuejs/core/pull/12509 is merged
  // eslint-disable-next-line vue/no-boolean-default
  visible: false,
})

const emit = defineEmits<{
  close: []
}>()

const { t } = useI18n()

const mobileOverlayFooterRef = ref<HTMLDivElement>()
</script>

<i18n lang="json">
{
  "en": {
    "title": "Filters"
  },
  "fr": {
    "title": "Filtres"
  }
}
</i18n>
