<template>
  <div :class="['listing-form', { expanded }]">
    <div class="heading">
      <slot
        name="heading"
        :expanded="expanded"
      >
        <div>
          <ZImage
            :src="rvImage"
            alt=""
            rounded
            fluid
            class="expand-mobile d-lg-none"
            width="128"
          />

          <div>
            <div class="expand-mobile d-lg-none">
              {{ rvRVName }}
            </div>

            <EstimateNightlyRate :financial="bookingEstimateData?.Financial" />
          </div>
        </div>
      </slot>

      <ZClose
        v-if="expanded"
        class="expand-mobile d-lg-none"
        @click="closeBreakdown"
      />

      <ZButton
        v-if="!expanded"
        variant="highlight"
        link
        class="p-0 d-lg-none"
        data-testid="mobile-price-details"
        @click="expand"
      >
        {{ t('priceDetails') }}
      </ZButton>
    </div>

    <EstimateDateRange
      ref="dateRangeEl"
      class="expand-mobile"
    />

    <EstimateGuests class="expand-mobile mb-3" />

    <div class="grow expand-mobile">
      <template v-if="bookingEstimateData">
        <LazyEstimateUpsellTipbox
          v-if="bookingEstimateData?.UpsellBooking"
          :country-code="financialCountry"
          :original-price="financialRenterTotal"
          :original-days="financialTotalNights"
          :upsell-price="bookingEstimateData.UpsellBooking?.Financial?.RenterTotal ?? 0"
          :upsell-days="bookingEstimateData.UpsellBooking?.Financial?.TotalNights ?? 0"
        />

        <EstimateBreakdown
          :financial="bookingEstimateData?.Financial"
          :rv-snapshot="bookingEstimateData?.RVSnapshot"
          class="mt-lg-3"
          data-testid="price-breakdown"
        />

        <div class="total-breakdown">
          <EstimateTotal :financial="bookingEstimateData?.Financial" />
        </div>
      </template>

      <template v-else-if="!bookingEstimateData && !bookingEstimateError">
        <div class="cannotbook text-center mt-2 text-center">
          {{ t('cannotBook') }}
        </div>

        <ZTipbox
          v-if="rvHasRangeDiscounts"
          variant="highlight"
          fa-collection="far"
          fa-icon="tags"
          :title="t('discountsOffered.title')"
          class="my-4"
        >
          {{ t('discountsOffered.message') }}
        </ZTipbox>
      </template>

      <EstimateErrorTipbox />

      <EstimateDisclaimerInsuranceProtection
        v-if="canShowInsuranceDisclaimer"
        class="my-3"
      />

      <EstimateDisclaimerInternationalPricing class="expand-mobile d-lg-none my-2" />
    </div>

    <slot name="button">
      <EstimateButton
        :size="isLargeBreakpoint ? 'lg' : undefined"
        :disabled="rvOwnerId === user?.Id"
        @open-breakdown="openBreakdown"
        @close-breakdown="closeBreakdown"
      />
    </slot>

    <p
      v-if="bookingEstimateData && !bookingEstimateData.InstabookAvailable"
      class="d-none d-lg-block mt-2 text-center wontbecharged"
    >
      {{ t('youWontBeCharged') }}
    </p>

    <EstimateDisclaimerInternationalPricing class="d-none d-lg-block mt-2" />

    <ZOverlay
      no-wrap
      :show="bookingEstimatePending || deliveryCostPending"
      data-testid="listing-form-loader"
    />
  </div>
</template>

<script setup lang="ts">
import type { ComponentInstance } from 'vue'
import { useScrollLock } from '@vueuse/core'
import type { EstimateDateRange } from '#components'

const props = withDefaults(defineProps<{
  pageSource?: string
  showInsuranceDisclaimer?: boolean
}>(), {
  pageSource: '',
})

const { t } = useI18n()
const { isLargeBreakpoint } = useBreakpoint()

const { rvRVName, rvHasRangeDiscounts, rvOwnerId, rvCountry, rvMainPhotoPath, rvId } = useRvDetails()

const { user } = useAuthentication()

const {
  bookingEstimateError,
  bookingEstimateParams,
  bookingEstimatePending,
  bookingEstimateData,
  financialCountry,
  financialRenterTotal,
  financialTotalNights,
  rvSnapshotHasSmartPricing,
  rvSnapshotChargePerHourOver,
  rvSnapshotSurchargePerExtraKM,
  rvSnapshotFreeHoursPerDay,
} = useBookingEstimate()

const { deliveryCostPending } = useDeliveryCost()

const { isMobile } = useDevice()
const { routeBaseName } = useBaseName()

const expanded = ref(false)
const expandEventTracked = ref(false)
const { sendEventTripQuoteEvents } = useTracking()
const unwatch = watch(bookingEstimateData, () => {
  if (import.meta.server || !bookingEstimateData.value || rvOwnerId.value === user.value?.Id) {
    return
  }

  sendEventTripQuoteEvents({
    pageSource: props.pageSource || routeBaseName.value,
    sendTripQuotePresented: true,
    sendTripQuoteViewed: isMobile ? expanded.value : true,
    rvId: rvId.value,
    financial: bookingEstimateData.value?.Financial || null,
    snapshot: {
      hasSmartPricing: rvSnapshotHasSmartPricing.value,
      chargePerHourOver: rvSnapshotChargePerHourOver.value,
      surchargePerExtraKM: rvSnapshotSurchargePerExtraKM.value,
      freeHoursPerDay: rvSnapshotFreeHoursPerDay.value,
    },
    booking: {
      dateStart: bookingEstimateParams.value.dateStart,
      dateEnd: bookingEstimateParams.value.dateEnd,
      adults: bookingEstimateParams.value.adults,
      children: bookingEstimateParams.value.children,
      pets: bookingEstimateParams.value.pets,
      destination: bookingEstimateParams.value.destination?.name ?? '',
      rvUsage: bookingEstimateParams.value.rvUsage ?? '',
      deliveryDistance: bookingEstimateData.value?.DeliveryDistance ?? 0,
    },
  })

  expandEventTracked.value = false
}, {
  immediate: true,
})

onBeforeRouteLeave(() => {
  unwatch()
})

const dateRangeEl = ref<ComponentInstance<typeof EstimateDateRange>>()

function openBreakdown() {
  if (!isLargeBreakpoint.value) {
    expand()
  }

  // TODO: Check which type of error occurs instead of just opening the calendar.
  dateRangeEl.value?.openCalendar('start')
}

function closeBreakdown() {
  expanded.value = false
}

function expand() {
  expanded.value = true
}

const bodyScrollLocked = useScrollLock(document)
watch(expanded, (value) => {
  bodyScrollLocked.value = value

  if (expandEventTracked.value || !value || !bookingEstimateData.value || rvOwnerId.value === user.value?.Id) {
    return
  }

  sendEventTripQuoteEvents({
    pageSource: props.pageSource || routeBaseName.value,
    sendTripQuotePresented: false,
    sendTripQuoteViewed: value,
    rvId: rvId.value,
    financial: bookingEstimateData.value?.Financial || null,
    snapshot: {
      hasSmartPricing: rvSnapshotHasSmartPricing.value,
      chargePerHourOver: rvSnapshotChargePerHourOver.value,
      surchargePerExtraKM: rvSnapshotSurchargePerExtraKM.value,
      freeHoursPerDay: rvSnapshotFreeHoursPerDay.value,
    },
    booking: {
      dateStart: bookingEstimateParams.value.dateStart,
      dateEnd: bookingEstimateParams.value.dateEnd,
      adults: bookingEstimateParams.value.adults,
      children: bookingEstimateParams.value.children,
      pets: bookingEstimateParams.value.pets,
      destination: bookingEstimateParams.value.destination?.name ?? '',
      rvUsage: bookingEstimateParams.value.rvUsage ?? '',
      deliveryDistance: bookingEstimateData.value?.DeliveryDistance ?? 0,
    },
  })

  expandEventTracked.value = true
})

const { isInsurancePositionedAtPayment } = useInsurancePosition(rvCountry.value)
const canShowInsuranceDisclaimer = computed(() => {
  return props.showInsuranceDisclaimer && isInsurancePositionedAtPayment.value
})

const { getImageUrl } = useImageUrl()
const rvImage = computed(() => {
  return getImageUrl({
    path: rvMainPhotoPath.value,
    resolution: 'small',
    quality: 'high',
  })
})

defineExpose({
  openBreakdown,
  closeBreakdown,
})
</script>

<style lang="scss" scoped>
.listing-form {
  padding: 1rem;
  background-color: #fff;

  @include media-max-size(large) {
    box-shadow: 0 -.25rem .5rem rgba(0, 0, 0, .1);

    &:not(.expanded) {
      :deep(.expand-mobile) {
        display: none;
      }
    }

    &.expanded {
      height: 100%;
      height: 100vh;
      height: 100dvh;
      display: flex;
      flex-direction: column;
      overflow: auto;

      .grow {
        flex-grow: 1;
      }
    }
  }

  @include media-min-size(large) {
    border: 1px solid getColor('primary-100');
    border-radius: 0.5rem;
  }

  .total-breakdown {
    border-top: 1px solid getColor('primary-100');
    padding-top: 1rem;
  }

  .cannotbook {
    @include body-1;
    color: getColor('primary-350');
  }

  .wontbecharged {
    @include caption;
    color: getColor('primary-350');
  }
}

.heading {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-max-size(large) {
    margin-bottom: 1rem;

    .expanded & {
      display: grid;
      gap: 1rem;
      align-items: flex-start;
      grid-template-columns: 1fr auto;

      > div {
        display: grid;
        grid-template-columns: 8rem auto;
        grid-gap: 1rem;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "priceDetails": "Price details",
    "cannotBook": "Select your dates to get price details",
    "discountsOffered": {
      "title": "Discounts offered",
      "message": "Save when you book for a week or month"
    },
    "youWontBeCharged": "You won't be charged yet"
  },
  "fr": {
    "priceDetails": "Détails de prix",
    "cannotBook": "Sélectionnez vos dates pour obtenir les détails du tarif",
    "discountsOffered": {
      "title": "Rabais offerts",
      "message": "Économisez lorsque vous réservez pour une semaine ou un mois"
    },
    "youWontBeCharged": "Vous ne serez pas encore facturé"
  }
}
</i18n>
