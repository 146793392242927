import type { Nullable, VerifiedReviews } from '~/types'

export default async function useAggregateReviews() {
  const reviews = useState<Nullable<VerifiedReviews>>('aggregateReviews', () => null)

  if (reviews.value) return reviews

  const data = await $fetch<VerifiedReviews>('/api/verified-reviews')

  reviews.value = data

  return reviews
}
