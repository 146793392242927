import { COOKIES, MOBILE_CAPABILITIES } from '~/constants'

/**
 * This composable checks if the user is using a webview.
 *
 * @todo might be better to move this to a cookie-related composable?
 */
type MobileDevice = 'android' | 'ios-v1'
type Platform = 'mobile_web' | 'web' | MobileDevice

export default function usePlatform() {
  const platformCookie = useCookie(COOKIES.platform)

  const { isMobileOrTablet } = useDevice()
  const platform: ComputedRef<Platform> = computed(() => {
    return platformCookie.value ? platformCookie.value as MobileDevice : isMobileOrTablet ? 'mobile_web' : 'web'
  })

  const isWebViewAndroid = computed(() => platform.value === 'android')
  const isWebViewIOS = computed(() => platform.value === 'ios-v1')
  const isWebView = computed(() => isWebViewAndroid.value || isWebViewIOS.value)

  const capabilitiesCookie = useCookie(COOKIES.capabilities)
  const mobileCapabilities = computed(() => {
    return (Object.keys(MOBILE_CAPABILITIES) as MOBILE_CAPABILITIES[]).reduce((acc, capability) => {
      acc[capability] = Boolean(capabilitiesCookie.value?.includes(MOBILE_CAPABILITIES[capability]))
      return acc
    }, {} as Record<MOBILE_CAPABILITIES, boolean>)
  })

  return {
    isWebViewAndroid,
    isWebViewIOS,
    isWebView,
    mobileCapabilities,
    platform,
  }
}
