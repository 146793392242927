<template>
  <section class="rates">
    <h3 class="mb-3">
      {{ t('title') }}
    </h3>

    <ZGrid class="mt-2">
      <RvRentalDetailItem
        :label="t('daily')"
        class="mb-3"
      >
        <strong>{{ formatPrice({ value: rvDefaultPrice }) }}/<span class="text-lowercase">{{
          t('night')
        }}</span></strong>
      </RvRentalDetailItem>

      <RvRentalDetailItem
        :label="t('weekly')"
        class="mb-3"
      >
        <template v-if="weeklyDiscount > 0">
          <strong>{{ formatPrice({ value: rvWeeklyPrice }) }}/<span class="text-lowercase">{{
            t('night')
          }}</span></strong>
          <div class="percent-discount">
            {{ t('percentDiscount', { amount: weeklyDiscount }) }}
          </div>
        </template>
        <template v-else>
          <strong>{{ t('notApplicable') }}</strong>
        </template>
      </RvRentalDetailItem>

      <RvRentalDetailItem
        :label="t('monthly')"
        class="mb-3"
      >
        <template v-if="monthlyDiscount > 0">
          <strong>{{ formatPrice({ value: rvMonthlyPrice }) }}/<span class="text-lowercase">{{
            t('night')
          }}</span></strong>
          <div class="percent-discount">
            {{ t('percentDiscount', { amount: monthlyDiscount }) }}
          </div>
        </template>
        <template v-else>
          <strong>{{ t('notApplicable') }}</strong>
        </template>
      </RvRentalDetailItem>

      <RvRentalDetailItem
        v-if="rvSecurityDeposit && rvSecurityDeposit > 0"
        :label="t('securityDeposit.text')"
        class="mb-3"
      >
        <strong>{{ formatPrice({ value: rvSecurityDeposit }) }}</strong>
        {{ t('fullyRefundable') }}
        <ZTooltipIcon
          :content="t('securityDeposit.tooltip')"
          :title="t('securityDeposit.text')"
          icon-size="lg"
          class="ml-1"
        />
      </RvRentalDetailItem>

      <RvRentalDetailItem
        v-if="rvIsMotorized"
        :label="t('mileage')"
        class="mb-3"
      >
        <template v-if="rvIsChargeMileageEnabled">
          <strong>{{ t('dailyMileageAllowed', { amount: rvDailyKMAllowed, unit: rvMeasurementUnit }) }}</strong>
          <div>
            {{ formatPrice({ value: rvSurchargePerExtraKM as number, round: false }) }}/{{ t('surchargePerExtraMileage', { unit: rvMeasurementUnit }) }}
          </div>
        </template>
        <template v-else>
          <strong>{{ t('unlimited') }}</strong>
        </template>
      </RvRentalDetailItem>
      <RvRentalDetailItem
        v-if="rvIsGenerator"
        :label="t('powerGenerator')"
        class="mb-3"
      >
        <template v-if="rvFreeHoursPerDay && rvFreeHoursPerDay > 0">
          <strong>{{ t('FreeHoursPerDay', { amount: rvFreeHoursPerDay }) }}</strong>
          <div>{{ formatPrice({ value: rvChargePerHourOver as number, round: false }) }}/{{ t('ChargePerHourOver') }}</div>
        </template>
        <template v-else-if="rvIsChargeGeneratorEnabled">
          <strong>{{ formatPrice({ value: rvChargePerHourOver as number, round: false }) }}/{{ t('hour') }}</strong>
        </template>
        <template v-else>
          <strong>{{ t('unlimited') }}</strong>
        </template>
      </RvRentalDetailItem>
    </ZGrid>
  </section>
</template>

<script setup lang="ts">
const { priceDisplay } = usePrice()
const { t } = useI18n({
  useScope: 'local',
})

const {
  rvChargePerHourOver,
  rvCountry,
  rvDailyKMAllowed,
  rvDefaultPrice,
  rvFreeHoursPerDay,
  rvIsGenerator,
  rvIsChargeGeneratorEnabled,
  rvIsChargeMileageEnabled,
  rvMeasurementUnit,
  rvMonthlyDiscount,
  rvSecurityDeposit,
  rvSurchargePerExtraKM,
  rvWeeklyDiscount,
  rvIsMotorized,
  rvWeeklyPrice,
  rvMonthlyPrice,
} = useRvDetails()

const weeklyDiscount = computed(() => roundToNearestCent(rvWeeklyDiscount.value))
const monthlyDiscount = computed(() => roundToNearestCent(rvMonthlyDiscount.value))

function formatPrice({ value, round = true }: { value: number, round?: boolean }) {
  return priceDisplay({
    value,
    round,
    countryCode: rvCountry.value,
    internationalPricing: true,
  })
}
</script>

<style lang="scss" scoped>
section.rates {
  .collapsible-content {
    color: getColor('primary-500');
  }

  strong {
    @include strong-1;

    color: getColor('primary-500');

    @include media-min-size(large) {
      @include strong-2;
    }
  }

  .placeholder {
    @include media-max-size(large) {
      @include captionBase;
    }
  }

  .percent-discount {
    @include body-1;
    color: getColor('highlight-500');
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "Rates",
    "night": "Night",
    "mileage": "Mileage",
    "dailyMileageAllowed": "{amount}{unit}/day free",
    "surchargePerExtraMileage": "{unit} extra",
    "unlimited": "Unlimited",
    "powerGenerator": "Power Generator",
    "FreeHoursPerDay": "{amount} hours/day free",
    "ChargePerHourOver": "hour extra",
    "notApplicable": "N/A",
    "hour": "hour",
    "fullyRefundable": "Fully refundable",
    "percentDiscount": "{amount}% discount",
    "daily": "Daily",
    "monthly": "Monthly",
    "weekly": "Weekly",
    "securityDeposit": {
      "text": "Security Deposit",
      "tooltip": "This deposit is taken 72hrs prior to the rental date and held during the duration of the trip and is fully refundable."
    }
  },
  "fr": {
    "title": "Tarifs",
    "night": "Nuit",
    "mileage": "Kilométrage",
    "dailyMileageAllowed": "{amount}{unit}/jour gratuit",
    "surchargePerExtraMileage": "{unit} supplémentaire",
    "unlimited": "Illimité",
    "powerGenerator": "Génératrice",
    "FreeHoursPerDay": "{amount} heures/jour gratuit",
    "ChargePerHourOver": "heure supplémentaire",
    "notApplicable": "S/O",
    "hour": "heure",
    "fullyRefundable": "Totalement remboursable",
    "percentDiscount": "{amount}% de rabais",
    "daily": "Quotidien",
    "monthly": "Mensuel",
    "weekly": "Hebdomadaire",
    "securityDeposit": {
      "text": "Dépôt de sécurité",
      "tooltip": "Ce dépôt est pris 72h avant la location date et tenue pendant la durée du voyage et est entièrement remboursable."
    }
  }
}
</i18n>
