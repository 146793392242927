<template>
  <ZButton
    :link="isLink"
    :class="['buy-rv-btn', { 'darker-text': usePrimary200 }]"
    :variant="btnVariant"
    :outline="useWhite || useOutline"
    :href="targetUrl"
    target="_blank"
    @click.prevent="buyAnRvLinkClicked"
  >
    {{ textBtn }}
  </ZButton>
</template>

<script setup lang="ts">
const props = defineProps<{
  textBtn: string
  useHighlight?: boolean
  useWhite?: boolean
  isLink?: boolean
  usePrimary200?: boolean
  cta: string
  useOutline?: boolean
}>()

const { trackBuyRvClicked } = useTracking()
const { routeBaseName } = useBaseName()
const runtimeConfig = useRuntimeConfig()
const { locale } = useI18n()

const targetUrl = computed(() => `${runtimeConfig.public.apps.marketplace.baseURL}/${locale.value}`)

function buyAnRvLinkClicked() {
  trackBuyRvClicked({ pageSource: routeBaseName.value, cta: props.cta })
  window.open(targetUrl.value)
}

const btnVariant = computed(() => {
  return props.useHighlight ? 'highlight' : props.useWhite ? 'white' : 'primary'
})
</script>

<style scoped lang="scss">
.zlink {
  text-decoration: none;
  font-size: 0.875rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;

  @include media-max-size(xLarge) {
    font-size: 1rem;
    padding: 0;
  }
}
.darker-text {
  color: getColor('primary-200');
  font-size: 1rem;
  padding: 0;
  font-weight: 400;
}
</style>
