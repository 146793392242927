import { COOKIES } from '~/constants'

export default defineNuxtRouteMiddleware((to) => {
  const { isLoggedIn, user } = useAuthentication()
  const { $experiment: experiment } = useNuxtApp()
  const rtsessionIdCookie = useCookie(COOKIES.session)

  // Send user identifiers to Clarity
  if (isLoggedIn.value && experiment.isEnabled('rvz20755clarityFeatureFlag')) {
    const { sendIdentifiers } = useClarity()
    sendIdentifiers({ customId: user.value?.Email ?? '', customPageId: to.name?.toString(), customSessionId: rtsessionIdCookie.value ?? '' })
  }
})
