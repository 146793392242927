import { SendConsentToClarity, SendEventToClarity, SendIdentifiersToClarity, SendTagToClarity } from '~/lib/tracking/clarityTrack'
import type { IdentifiersType } from '~/types/clarity'

export default function useClarity() {
  function sendEvent(eventName: string) {
    SendEventToClarity(eventName)
  }

  function sendConsent() {
    SendConsentToClarity()
  }

  function sendTag(tagName: string, tagValue: string) {
    SendTagToClarity(tagName, tagValue)
  }

  function sendIdentifiers(args: IdentifiersType) {
    SendIdentifiersToClarity(args)
  }

  return {
    sendEvent,
    sendConsent,
    sendTag,
    sendIdentifiers,
  }
}
