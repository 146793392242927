<template>
  <div>
    <NuxtLayout :name="layout">
      <template v-if="isRvUnpublishedError">
        <LazyAppErrorsRvUnpublished
          :error="props.error"
        />
      </template>

      <AppErrorsDefault
        v-else
        :error="error"
      />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{
  error: NuxtError
}>()

const { t } = useI18n()

const isRvUnpublishedError = computed(() => props.error.message === 'RV_UNPUBLISHED')

const { getLayout } = useLayout()
const layout = computed(() => getLayout())

const title = computed(() => {
  return isRvUnpublishedError.value
    ? t('pages.error.title.rvUnpublished')
    : props.error.statusCode === 404
      ? t('pages.error.title.fourOhFour')
      : t('pages.error.title.others')
})

useHead({
  title,
})
</script>

<i18n src="~/locales/common/pages/error.json" lang="json" />
