<template>
  <component
    :is="modal"
    :visible="visible"
    v-bind="modalData"
    @hide="showNext"
  />
</template>

<script setup lang="ts">
import type { ModalData } from '~/types'

const { modalsQueue, showNextModal } = useModals()

const currentModalTag = computed(() => modalsQueue.value.queue[modalsQueue.value.index]?.tag)
const modal = computed(() => {
  if (!currentModalTag.value) {
    return null
  }

  return defineAsyncComponent(() => currentModalTag.value!().then((module) => module.default))
})
const visible = computed(() => modalsQueue.value.isVisible)
const modalData = computed(() => modalsQueue.value.queue[modalsQueue.value.index]?.data || {})
const onClose = computed(() => modalsQueue.value.queue[modalsQueue.value.index]?.onClose)

function showNext(data: ModalData) {
  if (onClose.value) {
    onClose.value(data)
  }
  showNextModal()
}
</script>
