<template>
  <div
    :class="computedClasses"
    @click="emit('click')"
  >
    <template v-if="noBody">
      <slot />
    </template>
    <ZCardBody
      v-else
      :title="title"
    >
      <slot />
    </ZCardBody>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  noBody?: boolean
  title?: string
  noBorder?: boolean
}>()

const emit = defineEmits<{
  click: []
}>()

const computedClasses = computed(() => [
  'zcard',
  {
    'zcard-borderless': props.noBorder,
  },
])
</script>
