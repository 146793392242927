<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  cols?: number | string
  xs?: number | string
  sm?: number | string
  md?: number | string
  lg?: number | string
  xl?: number | string
  xxl?: number | string
  xxxl?: number | string
  start?: number | string
  startLg?: number | string
  startXl?: number | string
}>(), {
  cols: 12,
})

function isValidSpan(span?: number | string) {
  if (!span) {
    return false
  }

  const newSpan = typeof span === 'number' ? span : parseInt(span)

  return newSpan >= 1 && newSpan <= 12
}

const classes = computed(() => [
  'zgrid-item',
  {
    [`g-col-${props.cols}`]: isValidSpan(props.cols),
    [`g-col-xs-${props.xs}`]: isValidSpan(props.xs),
    [`g-col-sm-${props.sm}`]: isValidSpan(props.sm),
    [`g-col-md-${props.md}`]: isValidSpan(props.md),
    [`g-col-lg-${props.lg}`]: isValidSpan(props.lg),
    [`g-col-xl-${props.xl}`]: isValidSpan(props.xl),
    [`g-col-xxl-${props.xxl}`]: isValidSpan(props.xxl),
    [`g-col-xxxl-${props.xxxl}`]: isValidSpan(props.xxxl),
    [`g-col-start-${props.start}`]: isValidSpan(props.start),
    [`g-col-start-lg-${props.startLg}`]: isValidSpan(props.startLg),
    [`g-col-start-xl-${props.startXl}`]: isValidSpan(props.startXl),
  },
])
</script>
