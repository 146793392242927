<template>
  <li role="presentation">
    <component
      :is="tag"
      v-bind="$attrs"
      :class="computedClasses"
    >
      <slot />
    </component>
  </li>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

const props = defineProps<{
  button?: boolean
  active?: boolean
  linkClasses?: string | string[]
}>()

defineOptions({
  inheritAttrs: false,
})

const tag = computed(() => props.button ? 'button' : NuxtLink)

const computedClasses = computed(() => [
  'zdropdown-item',
  {
    'zdropdown-item-active': props.active,
  },
  props.linkClasses,
])
</script>
