<template>
  <div :class="computedClasses">
    <label
      v-if="hasLabel && !isFieldset"
      :for="labelFor"
      class="d-block"
    >
      <template v-if="label">{{ label }}</template>
      <template v-else><slot name="label" /></template>
    </label>
    <fieldset
      v-else-if="hasLabel && isFieldset"
      class="z-form-group mb-0"
    >
      <legend
        tabindex="-1"
        :class="legendClasses"
      >
        <div class="field-label">
          <template v-if="label">
            {{ label }}
          </template>
          <template v-else>
            <slot name="label" />
          </template>
        </div>
      </legend>
    </fieldset>
    <slot />
    <ZFormInvalidFeedback
      :is-invalid="isInvalid"
      :tab-index="invalidFeedback ? '-1' : null"
      :aria-live="feedbackAriaLive"
    >
      <slot
        v-if="hasInvalidFeedbackSlot"
        name="invalid-feedback"
      />
      <template v-else>
        {{ invalidFeedback }}
      </template>
    </ZFormInvalidFeedback>
    <small
      v-if="description"
      tabindex="-1"
      class="form-text text-muted"
    >
      {{ description }}
    </small>
  </div>
</template>

<script setup lang="ts">
import type { FormGroupAriaLiveOptions } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  label?: string
  labelFor?: string
  isInvalid?: boolean
  invalidFeedback?: string
  description?: string
  feedbackAriaLive?: FormGroupAriaLiveOptions
}>(), {
  feedbackAriaLive: 'assertive',
})

const computedClasses = computed(() => {
  return [
    'z-form-group',
    {
      'is-invalid': props.isInvalid,
    },
  ]
})

const isFieldset = computed(() => {
  return !props.labelFor
})

const slots = useSlots()
const hasInvalidFeedbackSlot = computed(() => slots['invalid-feedback'])
const hasLabel = computed(() => props.label || slots.label)

const legendClasses = computed(() => [
  'pt-0',
  'z-no-focus-ring',
  {
    'col-form-label': hasLabel.value,
  },
])
</script>
