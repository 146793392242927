<template>
  <div>
    <span
      v-if="hasDiscountedNightlyPrice"
      class="original-price"
    >
      {{ formatPrice({ value: baseNightlyPrice }) }}
    </span>
    <span
      class="price"
      data-testid="price-per-night"
    >{{ formatPrice({ value: actualNightlyPrice, showCurrencyCode: true }) }}</span>/{{ t('night') }}
  </div>
</template>

<script setup lang="ts">
import type { FinancialCommon, Nullable } from '~/types'

const props = defineProps<{
  financial?: Nullable<FinancialCommon>
}>()

const { t } = useI18n()
const { financialNightlyBase, financialNightlyDiscounted } = useFinancialData(computed(() => props.financial))
const { rvCountry, rvDefaultPrice } = useRvDetails()

const actualNightlyPrice = computed(() => {
  return financialNightlyDiscounted.value || baseNightlyPrice.value
})

const baseNightlyPrice = computed(() => {
  return financialNightlyBase.value || rvDefaultPrice.value
})

const hasDiscountedNightlyPrice = computed(() => baseNightlyPrice.value > actualNightlyPrice.value)

const { priceDisplay } = usePrice()
function formatPrice({ value, showCurrencyCode }: { value: number, showCurrencyCode?: boolean }) {
  return priceDisplay({
    value,
    countryCode: rvCountry.value,
    round: true,
    internationalPricing: true,
    showCurrencyCode,
  })
}
</script>

<style lang="scss" scoped>
div {
  @include caption;

  @include media-min-size(medium) {
    @include body-1;
  }

  .original-price {
    color: getColor('primary-350');
    text-decoration-line: line-through;
    margin-right: 0.5rem;
  }

  .price {
    @include strong-1;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "night": "night"
  },
  "fr": {
    "night": "nuit"
  }
}
</i18n>
