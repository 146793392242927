const VIDEO_FORMAT = {
  mp4: 'mp4',
  webm: 'webm',
}

const VIDEO_RESOLUTION = {
  '240p': {
    width: 426,
    height: 240,
  },
  '360p': {
    width: 640,
    height: 360,
  },
  '480p': {
    width: 854,
    height: 480,
  },
  '720p': {
    width: 1280,
    height: 720,
  },
  '1080p': {
    width: 1920,
    height: 1080,
  },
}

export const videoUrl = function ({
  baseUrl,
  dynamicAssetsUrl,
  dynamicAssetsUrlProd,
  id,
  format = VIDEO_FORMAT.mp4,
  resolution = VIDEO_RESOLUTION['1080p'],
}: {
  baseUrl: string
  dynamicAssetsUrl: string
  dynamicAssetsUrlProd: string
  id?: string
  format?: string
  resolution?: {
    width: number
    height: number
  }
}) {
  if (!dynamicAssetsUrl || !dynamicAssetsUrlProd) return

  format = format.toLowerCase()

  const { width, height } = resolution
  const isProduction = baseUrl === 'https://www.rvezy.com'

  return {
    thumbnail: `${isProduction ? dynamicAssetsUrlProd : dynamicAssetsUrl}/video/${id}/thumbnail.jpg`,
    source: `${isProduction ? dynamicAssetsUrlProd : dynamicAssetsUrl}/video/${id}/${width}x${height}.${format}`,
    width: width,
    height: height,
  }
}
