<template>
  <div class="collapsible-content">
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="trustedHtml"
      ref="contentbox"
      :class="{ content, safari: isSafari }"
      :style="{ 'lineClamp': expanded ? undefined : lineClamp, '-webkit-line-clamp': expanded ? undefined : lineClamp }"
      v-html="content.trim()"
    />
    <!-- eslint-enable vue/no-v-html -->
    <div
      v-else
      ref="contentbox"
      :class="['content', { safari: isSafari }]"
      :style="{ 'lineClamp': expanded ? undefined : lineClamp, '-webkit-line-clamp': expanded ? undefined : lineClamp }"
    >
      {{ content }}
    </div>
    <ZButton
      v-if="showExpander"
      link
      :variant="moreButtonVariant"
      class="collapse-toggle"
      @click="expanded = !expanded"
    >
      <template v-if="isShowMoreButton">
        {{ expanded ? t('actions.showLess') : t('actions.showMore') }}
      </template>

      <template v-else>
        {{ expanded ? t('actions.readLess') : t('actions.readMore') }}
      </template>
    </ZButton>
  </div>
</template>

<script setup lang="ts">
import type { ButtonVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  content: string
  lineClamp?: number
  trustedHtml?: boolean
  isShowMoreButton?: boolean
  moreButtonVariant?: ButtonVariant
  close?: boolean
}>(), {
  lineClamp: 0,
  moreButtonVariant: 'highlight',
})

const { t } = useI18n()
const { isSafari } = useDevice()

const contentbox = ref<HTMLDivElement>()
const expanded = ref(false)

const showExpander = computed(() => {
  return (
    props.lineClamp > 0
    && (props.content.split(/\r\n|\r|\n/).length > 3 || (contentbox.value?.scrollHeight || 0) > (contentbox.value?.clientHeight || 0))
  )
})

watch(
  () => props.close,
  (newCloseValue) => {
    if (newCloseValue) {
      expanded.value = false
    }
  },
)
</script>

<style lang="scss" scoped>
.collapsible-content {
  p {
    margin-bottom: 0;
  }

  p + p {
    margin-top: 1rem;
  }

  /* Safari - Fix broken lines into IOS */
  .safari {
    :deep(p) {
      display: inline;
    }
  }

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: pre-line;
    word-break: break-word;
    -webkit-box-orient: vertical;
  }
  .collapse-toggle {
    margin-top: 1rem;
    padding: 0;
    border: 0;
  }
}
</style>
