<template>
  <div
    class="app-link"
    data-testid="download-banner"
    @click="downloadApp()"
  >
    <div class="app-download-banner">
      <ZClose
        data-testid="close-button"
        @click.stop="dismissBanner"
      />
      <div>
        <IconSvg class="logo" />
      </div>
      <div>
        <div class="title">
          {{ t('rvezy') }}
        </div>
        <div class="subtitle">
          {{ t('openInApp') }}
        </div>
      </div>
      <ZButton
        size="sm"
        variant="highlight"
        :class="['cta', { 'p-0': locale === 'fr' }]"
      >
        {{ t('open') }}
      </ZButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { trackAppBannerClosed } from '~/lib/tracking'
import { COOKIES } from '~/constants'

import IconSvg from '~/assets/images/rvezy-mini.svg'
import getOS from '~/lib/getOS.js'

const emit = defineEmits<{
  bannerDismissed: []
}>()

const { t, locale } = useI18n()

const { downloadApp } = useDownloadApp()
const { $device } = useNuxtApp()
const { routeBaseName } = useBaseName()

const appBannerCookie = useCookie(COOKIES.appDownloadBannerDismissed, {
  path: '/',
  expires: dates().add(2, 'days').toDate(),
})

function dismissBanner() {
  trackAppBannerClosed({
    os: getOS($device),
    pageSource: routeBaseName.value,
    cta: 'dismissBanner',
  })
  appBannerCookie.value = '1'
  emit('bannerDismissed')
}
</script>

<style lang="scss" scoped>
.app-download-banner {
  display: grid;
  grid-template-columns: 2rem 1.5rem auto 5.7125rem;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.5rem;
  position: relative;
  z-index: $headerZindex;
  border-bottom: 0.0625rem solid getColor('primary-100');

  button.zclose {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    color: getColor('gray-200');

    :deep(svg) {
      margin-top: 0.1875rem;
      width: 1rem;
      height: 1rem;
    }
  }

  svg.logo {
    width: 1.5rem;
    height: 1.5rem;
  }

  .title {
    color: $color-black;
    font-size: 0.75rem;
    font-weight: 600;
  }

  .subtitle {
    color: getColor('gray-300');
    font-size: 0.625rem;
  }

  button.cta {
    height: 1.9375rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "rvezy": "RVezy",
    "openInApp": "The fastest, easiest way to rent an RV",
    "open": "Open",
    "useApp": "Use app"
  },
  "fr": {
    "rvezy": "RVezy",
    "openInApp": "Le moyen le plus rapide et facile de louer un VR",
    "open": "Ouvrir",
    "useApp": "PAR ICI"
  }
}
</i18n>
