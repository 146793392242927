<template>
  <div
    ref="card"
    v-element-visibility="scrolledIntoView"
    scrollable
    :title="campground?.name || ''"
    :class="['campground-card', { pulse: isStillLoading }]"
    :data-testid="`campground-card-${index}`"
  >
    <div
      class="campground-images"
      @click.prevent="campgroundClickHandler"
    >
      <Transition name="fade">
        <div
          v-if="isStillLoading"
          key="loader"
          class="skel"
        />
        <LazyAppCarouselImages
          v-else
          key="content"
          :images="photos"
          :img-width="450"
          :img-height="300"
          resolution="medium"
          :preload="Boolean(photos.length && index === 0)"
          :eager-loading="index === 0 && isVisible"
          @click="onCarouselClick"
          @slide-changed="updateImageIndex"
        />
      </Transition>
    </div>

    <div class="campground-info">
      <div class="campground-header">
        <Transition name="fade">
          <div
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <div
            v-else
            class="campground-title"
          >
            {{ campground?.name }}
          </div>
        </Transition>
      </div>

      <div class="campground-meta">
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel skel2 w-4/5"
          />
          <p
            v-else
            key="content"
          >
            {{ campground?.placeName }}
            ({{ t('away', { distance: distanceAway }) }})
          </p>
        </Transition>
      </div>

      <div class="campground-link">
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <p
            v-else
            key="content"
          >
            <ZButton
              v-if="isIos"
              link
              variant="primary"
              :href="campgroundLink"
            >
              {{ t('moreInfo') }}
            </ZButton>
            <ZButton
              v-else
              link
              variant="primary"
              :href="campgroundLink"
              @click.prevent="moreInfoClickHandler"
            >
              {{ t('moreInfo') }}
            </ZButton>
          </p>
        </Transition>
      </div>

      <div class="campground-delivery">
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <LazyEstimateAddDelivery
            v-else-if="showDelivery"
            key="content"
            :destination="destinationData"
            @click="addDeliveryClickHandler"
          />
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { vElementVisibility } from '@vueuse/components'
import type { COUNTRY_SHORTS } from '~/constants'
import { DistanceUnitEnumKey } from '~/lib/enums'
import { trackCTAClicked, trackExternalLinkClicked } from '~/lib/tracking'
import type { BookingEstimateDestination } from '~/types'
import type { Campground } from '~/types/campground'

const props = withDefaults(defineProps<{
  /**
   * The actual index of the item in the list. This is used to emit back to the
   * list in order to tell the list when to load the next set of items.
   */
  index?: number
  campground?: Campground
  distanceUnit: DistanceUnitEnumKey
  isLoading?: boolean
  showDelivery?: boolean
  noLazyLoad?: boolean
}>(), {
  index: 0,
})

const emit = defineEmits<{
  'scrolled-into-view': [index: number]
}>()

const runtimeConfig = useRuntimeConfig()
const { t } = useI18n()
const { routeBaseName } = useBaseName()
const { isIos } = useDevice()

const imageIndex = ref(0)
/**
 * Tracks wether or not the card is scrolled into view.
 */
const isVisible = ref(false)

/**
 * The card can be in 2 loading "states":
 * 1. The data is actually still loading from the API.
 * 2. The card hasn't scrolled into view yet (if enabled).
 */
const isStillLoading = computed(() => props.isLoading || !isVisible.value)

const distanceAway = computed(() => {
  if (!props.campground?.distance) {
    return ''
  }

  return `${Math.round(props.distanceUnit === DistanceUnitEnumKey.mi ? kmToMi(props.campground.distance) : props.campground.distance)} ${props.distanceUnit}`
})

const photos = computed(() => {
  if (!props.campground?.photos) {
    return []
  }

  return props.campground.photos.map((path: string) => ({
    path: `${runtimeConfig.public.apps.campgrounds.assetsURL}/Campground/${props.campground?.id}/${path}?width=300&height=200`,
    alt: props.campground?.name,
  }))
})

const { getCampgroundsUrl, getCampgroundUrl } = useCampgroundsUrl()

const campgroundLink = computed(() => {
  // Early exit if we don't have a campground id with the based campground link.
  if (!props.campground?.id) return getCampgroundsUrl()

  return getCampgroundUrl(props.campground.id)
})

onBeforeMount(() => {
  /**
   * Determine wether or not we want to only show data when the card is
   * scrolled into view.
   */
  isVisible.value = props.noLazyLoad
})

function scrolledIntoView(visible: boolean) {
  /**
   * If we don't want to lazy load content, or when the card has scrolled
   * out of view, exit early.
   *
   * Otherwise fire the 'scrolled-into-view' event and track it locally.
   */
  if (props.noLazyLoad || !visible) return

  emit('scrolled-into-view', props.index)

  isVisible.value = visible
}

function onCarouselClick(e: MouseEvent) {
  if ((e.target as HTMLElement).closest('.glide__bullet') || (e.target as HTMLElement).closest('.glide__arrow')) {
    e.stopPropagation()
    e.preventDefault()
  }
}

function updateImageIndex(index: number) {
  imageIndex.value = index
}

function moreInfoClickHandler() {
  if (isStillLoading.value) return

  trackExternalLinkClicked({
    pageSource: routeBaseName.value,
    cta: 'moreInfo',
    externalLink: campgroundLink.value,
  })

  window.open(campgroundLink.value)
}

const destinationData = computed(() => ({
  latitude: props.campground?.latitude,
  longitude: props.campground?.longitude,
  types: [
    'campground',
    'establishment',
    'lodging',
    'park',
    'point_of_interest',
    'rv_park',
  ],
  name: props.campground?.name ?? undefined,
  placeId: '', // PlaceId is not Google PlaceId on Campground API
  country: props.campground?.countryCode as COUNTRY_SHORTS,
} as BookingEstimateDestination))

function addDeliveryClickHandler() {
  if (isStillLoading.value) return

  trackCTAClicked({
    pageSource: routeBaseName.value,
    cta: 'addDelivery',
  })
}

function campgroundClickHandler() {
  if (isStillLoading.value) return

  trackExternalLinkClicked({
    pageSource: routeBaseName.value,
    cta: 'carouselImage',
    externalLink: campgroundLink.value,
  })

  window.open(campgroundLink.value)
}
</script>

<style lang="scss" scoped>
.pulse {
  cursor: wait;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
}

.skel {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
  border-radius: 0.25rem;
  background-color: getColor('gray-100');

  + .skel2 {
    height: 20px;
    top: 20px;
  }
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: calc(100% * (1 / 3));
}

.w-2\/3 {
  width: calc(100% * (2 / 3));
}

.w-4\/5 {
  width: calc(100% * (4 / 5));
}

.campground-images {
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  padding-bottom: calc(2 / 3 * 100%);
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 0.75rem;
}

.campground-card {
  position: relative;
  overflow: hidden;
  color: getColor('primary-500');

  &:hover {
    text-decoration: none;
  }

  .campground-image {
    border-radius: 1rem;
    overflow: hidden;

    :deep(img) {
      border-radius: 1rem;
    }
  }

  .campground-header {
    position: relative;
    height: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .campground-title {
    @include strong-1;

    position: relative;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.5rem;
    margin: 0;
  }

  .campground-meta {
    @include caption;

    color: getColor('primary-350');
    position: relative;

    p {
      margin: 0;
    }
  }

  .campground-info {
    position: relative;
    margin-top: 0.75rem;
    color: getColor('primary-500');

    &:hover {
      text-decoration: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "away": "{distance} away",
    "moreInfo": "More info",
    "addDelivery": "Add Delivery"
  },
  "fr": {
    "away": "à {distance}",
    "moreInfo": "Plus d'info",
    "addDelivery": "Ajouter la livraison"
  }
}
</i18n>
