import type { AUTHENTICATION_INTENT, AUTHENTICATION_PROVIDERS } from '~/constants/authentication'

// Look into moving this to useAuthentication
export default function () {
  const authenticationModal = useAuthenticationModal()
  const authenticationType = useAuthenticationType()
  const authenticationIntent = useAuthenticationIntent()
  const { authenticationProvider } = useAuthentication()

  const useEnquiryOwnerName = () => useState<string | undefined>('enquiryOwnerName', () => undefined)

  function showSignup(params?: {
    intent?: AUTHENTICATION_INTENT
    ownerName?: string
    provider?: AUTHENTICATION_PROVIDERS // Only support email for now
  }) {
    const { intent, ownerName, provider } = params ?? {}

    authenticationProvider.value = provider

    authenticationIntent.value = intent

    const enquiryOwnerName = useEnquiryOwnerName()
    enquiryOwnerName.value = ownerName

    authenticationType.value = 'Signup'

    authenticationModal.value = true
  }

  const isSignupVisible = computed(() => authenticationType.value === 'Signup')

  return {
    isSignupVisible,
    authenticationIntent,
    enquiryOwnerName: useEnquiryOwnerName(),
    showSignup,
  }
}
