<template>
  <div>
    <h2>{{ t('popularRVRentals') }}</h2>

    <GridList
      :results-length="featuredRvs?.length"
      :batch-size="pageSize"
      :rows="2"
    >
      <template #default="{ visibleResults, queueViewableImpression }">
        <CardRv
          v-for="(card, index) in visibleResults"
          :key="card.pregen_id"
          :index="index"
          :search-request-id="searchRequestId || ''"
          :rv="featuredRvs?.[index]"
          cta="featured"
          @visible:rv="queueViewableImpression"
        />
      </template>
    </GridList>

    <ZButton
      :to="localePath({ name: 'rv-search' })"
      link
    >
      {{ t('actions.showMeMore') }}
    </ZButton>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  noGeolocationPartition?: boolean
}>()

const NorthAmericaBoundingBox = {
  swLng: '-179.9',
  swLat: '18.8163608007951',
  neLng: '-52.5198306260026',
  neLat: '83.1473634999412',
}

const localePath = useLocalePath()
const { t } = useI18n()

const runtimeConfig = useRuntimeConfig()
const { geolocation } = useGeolocation()

const pageSize = 10
const params = computed(() => {
  const params = {
    PageSize: 0,
    FeaturedPageSize: pageSize,
  }

  if (!props.noGeolocationPartition && geolocation.value) {
    const { latitude, longitude, countryCode } = geolocation.value
    const { supportedCountries } = runtimeConfig.public
    if (latitude && longitude && countryCode && supportedCountries.includes(countryCode.toLowerCase())) {
      return {
        ...params,
        SearchLat: latitude,
        SearchLng: longitude,
        country: countryCode,
      }
    }
  }

  return {
    ...params,
    ...NorthAmericaBoundingBox,
  }
})

const { $rentals } = useNuxtApp()
const { data } = await useAsyncData(
  'featuredRVs',
  () => $rentals('/api/rvlistings/unified-search', { params: params.value }),
  {
    transform: transformFeaturedRVs,
    watch: [params],
  },
)

const searchRequestId = computed(() => data.value?.Id || '')
const featuredRvs = computed(() => data.value?.ListRVs || [])
</script>

<i18n lang="json">
{
  "en": {
    "popularRVRentals": "Popular RV Rentals"
  },
  "fr": {
    "popularRVRentals": "VR Populaires"
  }
}
</i18n>
