export default function useAddon() {
  const { t, te } = useI18n()

  function getAddonLabel({ slug, name }: { slug: string, name: string }) {
    if (!name) return ''

    if (slug === 'airport_pickup') {
      return `${t('DASHBOARD.ADDONS.AIRPORT_PICKUP')} - ${name}`
    }

    // Should we support legacy delivery addons?
    // } else if (addon.Slug.startsWith(DELIVERY_ADDON_PREFIX)) {
    //   const distance = getDeliveryDistanceBySlug(addon.Slug)

    //   if (country === 'US') {
    //     tempName = t('DASHBOARD.ADDONS.DELIVERY', {
    //       distance: distance.miles,
    //       unit: UNIT_MEASUREMENT.MILES
    //     })
    //   } else {
    //     tempName = t('DASHBOARD.ADDONS.DELIVERY', {
    //       distance: distance.km,
    //       unit: UNIT_MEASUREMENT.KM
    //     })
    //   }
    // }

    return te(name) ? t(name) : name
  }

  return {
    getAddonLabel,
  }
}
