<template>
  <nav
    :class="computedClasses"
  >
    <slot />
  </nav>
</template>

<script setup lang="ts">
import type { NavbarBreakpoint } from '~/types/style-guide'

const props = defineProps<{
  togglerBreakpoint?: NavbarBreakpoint
}>()

const computedClasses = computed(() => [
  'znavbar',
  props.togglerBreakpoint ? `znavbar-expand-${props.togglerBreakpoint}` : 'znavbar-expand',
])
</script>
