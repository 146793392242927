<template>
  <div class="upgrade-browser">
    <Fa
      :icon="['fad', 'circle-info']"
      class="info-icon"
    />
    <div class="body">
      <span>{{ t('upgrade-browser') }}</span>
      <span v-if="link"><a
        :href="link"
        target="_blank"
      >{{ t('upgrade-link-text') }}</a></span>
    </div>
    <div class="close">
      <ZClose @click="dismiss" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { COOKIES } from '~/constants'
import { TWO_WEEKS_IN_SECONDS } from '~/constants/durations'

const { t, locale } = useI18n()

const browserUpgradeCookie = useCookie(
  COOKIES.upgradeBrowser,
  {
    default: () => 0,
    maxAge: TWO_WEEKS_IN_SECONDS,
  },
)

const link = computed(() => {
  if (isChrome) {
    return locale.value === 'fr'
      ? 'https://support.google.com/chrome/answer/95414?hl=fr'
      : 'https://support.google.com/chrome/answer/95414'
  }

  if (isEdge) {
    return locale.value === 'fr'
      ? 'https://support.microsoft.com/fr-fr/topic/microsoft-edge-update-settings-af8aaca2-1b69-4870-94fe-18822dbb7ef1'
      : 'https://support.microsoft.com/en-us/topic/microsoft-edge-update-settings-af8aaca2-1b69-4870-94fe-18822dbb7ef1'
  }

  if (isFirefox) {
    return locale.value === 'fr'
      ? 'https://support.mozilla.org/fr/kb/mettre-jour-firefox-derniere-version'
      : 'https://support.mozilla.org/en-US/kb/update-firefox-latest-release'
  }

  if (isSafari) {
    return locale.value === 'fr'
      ? 'https://support.apple.com/fr-ca/102665'
      : 'https://support.apple.com/en-ca/102665'
  }

  return null
})

function dismiss() {
  browserUpgradeCookie.value = 1
}

const {
  isChrome,
  isEdge,
  isFirefox,
  isSafari,
} = await checkBrowserSupport(useRequestHeader('User-Agent'))
</script>

<style scoped lang="scss">
.upgrade-browser {
  position: relative;
  z-index: 1;
  padding: 16px;
  background-color: getColor('highlight-75');
  color: getColor('primary-500');
  @include caption;

  display: flex;
  gap: 8px;

  @include media-min-size(large) {
    align-items: center;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @include media-min-size(large) {
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.close {
  margin-left: auto;

  @include media-min-size(large) {
    margin-left: 2rem;
  }
}

:deep(.zclose) {
  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 0.875rem;
    height: 0.875rem;
  }
}

:deep(.svg-inline--fa) {
  width: 1.25rem;
  height: 1.25rem;
}

.info-icon :deep() {
  path:first-child {
    color: getColor('highlight-500');
  }

  path:last-child {
    color: white;
    opacity: 1;
  }
}

a {
  text-decoration: underline;
  color: getColor('highlight-500');
  font-weight: 600;
}
</style>

<i18n lang="json">
{
  "en": {
    "upgrade-browser": "Your browser is outdated. Update your browser for the best experience.",
    "upgrade-link-text": "Learn how to update"
  },
  "fr": {
    "upgrade-browser": "Votre navigateur est obsolète. Mettez à jour votre navigateur pour la meilleure expérience.",
    "upgrade-link-text": "Apprendre comment mettre à jour"
  }
}
</i18n>
