import { toRefs } from '@vueuse/core'
import type { ExpandedRvDetails, Nullable } from '~/types'

/**
 * Wraps the getRvDetails util function with a reactive data source and
 * updates the global state if new data is provided.
 *
 * This way the RV data from the global state can be used, or a new RV object
 * can be provided to update the global state.
 *
 * Using toRefs also keeps reactivity after destructuring.
 */
export default function useRvDetails(data?: Ref<Nullable<ExpandedRvDetails>>) {
  // The RV data from the global state.
  const rv = useSelectedRvDetails()

  // If a new RV object is provided, update the global state.
  if (data !== undefined) {
    watch(data, (newData) => {
      rv.value = newData
    }, {
      immediate: true,
    })
  }

  // Compute all the RV details.
  const rvDetails = computed(() => getRvDetails(rv?.value))

  // Return the object with `toRefs` to support reactive destructuring.
  return toRefs(rvDetails)
}
