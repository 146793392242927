export default function useSeoHeadTags(
  { title, description, image }:
  { title: () => string, description: () => string, image?: () => string | undefined },
) {
  const runtimeConfig = useRuntimeConfig()
  const route = useRoute()

  const head = useLocaleHead({
    seo: true,
  })

  // Hack to prevent double og:url properies
  // https://github.com/nuxt-modules/i18n/issues/2651
  // The type here should be inferred correctly, but it's not. For some reason,
  // `meta` is inferred as `any`.
  head.value.meta = head.value?.meta?.filter((t) => t.hid !== 'i18n-og-url')

  // On the blog, we want to remove the alternate links.
  if (route.fullPath.includes('/blog')) {
    head.value.link = head.value?.link?.filter((t) => !t.hid.startsWith('i18n-alt') && !t.hid.startsWith('i18n-xd'))
  }

  useHead({
    htmlAttrs: {
      lang: head.value.htmlAttrs?.lang,
    },
    link: [...(head.value.link || [])],
    meta: [...(head.value.meta || [])],
  })

  useSeoMeta({
    title,
    description,

    ogType: 'website',
    ogTitle: title,
    ogDescription: description,
    ogUrl: `https://www.rvezy.com${route.fullPath}`,
    ogImage: image ?? `${runtimeConfig.public.staticAssetsURL}/web-app/share-img.jpg`,

    twitterCard: 'summary_large_image',
    twitterTitle: title,
    twitterDescription: description,
    twitterSite: `https://www.rvezy.com${route.fullPath}`,
    twitterImage: image ?? `${runtimeConfig.public.staticAssetsURL}/web-app/share-img.jpg`,
  })
}
