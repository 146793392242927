<template>
  <div class="guests-grid">
    <div>{{ t('guests.adults') }}</div>
    <FormInputQuantitySelector
      data-test-id-suffix="booking-adults"
      :quantity="adults"
      :min-value="minAdults"
      :max-value="maxGuests - children"
      @change="updateAdults"
    />

    <div>{{ t('guests.children') }}</div>
    <FormInputQuantitySelector
      data-test-id-suffix="booking-children"
      :quantity="children"
      :max-value="maxGuests - adults"
      @change="updateChildren"
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  adults: number
  children: number
  minAdults?: number
  maxGuests?: number
}>(), {
  minAdults: 1,
  maxGuests: 99,
})

const emit = defineEmits<{
  'update:adults': [adults: number]
  'update:children': [children: number]
  'update:guests': [{ adults: number, children: number }]
}>()

const { t } = useI18n()

function updateAdults(adults: number) {
  emit('update:adults', adults)
  emit('update:guests', { adults, children: props.children })
}

function updateChildren(children: number) {
  emit('update:children', children)
  emit('update:guests', { adults: props.adults, children })
}
</script>

<style lang="scss" scoped>
.guests-grid {
  color: getColor('primary-500');
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  .qty-selector {
    justify-self: flex-end;
  }
}
</style>

<i18n src="~/locales/common/search/form/guests.json" lang="json" />
