<template>
  <div :class="containerClass">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  fluid?: boolean
}>()

const containerClass = computed(() => {
  return props.fluid ? 'zcontainer-fluid' : 'zcontainer'
})
</script>
