<template>
  <img
    :class="computedClasses"
    :loading="loading"
  >
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  fluid?: boolean
  rounded?: boolean
  roundedTop?: boolean
  center?: boolean
  loading?: 'eager' | 'lazy'
  card?: boolean
  preserveAspect?: boolean
}>(), {
  loading: 'lazy',
})

const computedClasses = computed(() => [
  'zimage',
  {
    'zimage-fluid': props.fluid,
    'zimage-rounded': props.rounded,
    'zimage-center': props.center,
    'zimage-card': props.card,
    'zimage-rounded-top': props.roundedTop,
    'zimage-preserve-aspect': props.preserveAspect,
  },
])
</script>
