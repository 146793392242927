<template>
  <span :class="computedClasses" />
</template>

<script setup lang="ts">
import type { SpinnerSize, SpinnerVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  size?: SpinnerSize
  variant?: SpinnerVariant
}>(), {
  size: 'md',
  variant: 'primary',
})

const computedClasses = computed(() => [
  'zspinner',
  `zspinner-${props.size}`,
  `zspinner-${props.variant}`,
])
</script>
