import type { LocationQueryValue } from 'vue-router'

import type { COUNTRY_SHORTS } from '~/constants'
import type { BookingPriceRequest } from '~/types/rental-api-aliases'
import type { Nullable } from '~/types'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $gis } = useNuxtApp()

  const { bookingEstimateParams, updateDateRange, updateDestination, updateGuests, updateRvUsage } = useBookingEstimate()

  const normalizedQuery: Record<string, LocationQueryValue | LocationQueryValue[]> = Object.keys(to.query).reduce((acc, k) => {
    acc[k.toLowerCase()] = to.query[k]
    return acc
  }, {} as Record<string, LocationQueryValue | LocationQueryValue[]>)

  const { adults, children, startdate, enddate, rvusage, petfriendly, placeid } = normalizedQuery

  // Remove duplicate dates query parameters
  let rewriteDates = Array.isArray(startdate) || Array.isArray(enddate)
  let newStartDate: Nullable<string | undefined> = Array.isArray(startdate) ? startdate?.[0] : startdate
  let newEndDate: Nullable<string | undefined> = Array.isArray(enddate) ? enddate?.[0] : enddate

  const start = dates(newStartDate)
  const end = dates(newEndDate)

  // Check if the date is valid and not in the past
  if (newStartDate === null || !start.isValid() || start.isBefore(dates().startOf('day'))) {
    newStartDate = undefined
    rewriteDates = true
  }

  if (newEndDate === null || !end.isValid() || end.isBefore(dates().startOf('day'))) {
    newEndDate = undefined
    rewriteDates = true
  }

  // Swap dates if EndDate is before StartDate
  if (newStartDate && newEndDate && end.isBefore(start)) {
    [newStartDate, newEndDate] = [newEndDate, newStartDate]
    rewriteDates = true
  }

  // Rewrite dates if needed
  if (rewriteDates) {
    return navigateTo({
      query: {
        ...to.query,
        StartDate: newStartDate,
        EndDate: newEndDate,
      },
    }, {
      external: true,
    })
  }

  if (newStartDate !== bookingEstimateParams.value.dateStart || newEndDate !== bookingEstimateParams.value.dateEnd) {
    updateDateRange({ dateStart: newStartDate ?? null, dateEnd: newEndDate ?? null })
  }

  const newAdultsString = Array.isArray(adults) ? adults?.[0] : adults
  const newChildrenString = Array.isArray(children) ? children?.[0] : children

  const newAdults = newAdultsString ? Number(newAdultsString) : undefined
  const newChildren = newChildrenString ? Number(newChildrenString) : undefined
  const newPets = petfriendly !== undefined

  if (newAdults !== bookingEstimateParams.value.adults || newChildren !== bookingEstimateParams.value.children || newPets !== bookingEstimateParams.value.pets) {
    updateGuests({
      adults: newAdults,
      children: newChildren,
      pets: newPets,
    })
  }

  if (placeid) {
    const newPlaceId = Array.isArray(placeid) ? placeid.join(' ') : placeid

    if (newPlaceId !== bookingEstimateParams.value.destination?.placeId) {
      const { name, coordinates, type, countryCode, address } = await $gis('/Place/Details', { query: { PlaceId: newPlaceId } })

      if (name && coordinates?.lat && coordinates?.lng && type && countryCode) {
        updateDestination({
          name: address?.startsWith(name) ? address : `${name}, ${address}`,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          types: [type],
          country: countryCode as COUNTRY_SHORTS,
          placeId: newPlaceId,
        })
      }
    }
  }

  if (rvusage) {
    // If no destination is set, remove the RvUsage query parameter
    if (!placeid) {
      return navigateTo({ query: { ...to.query, RvUsage: undefined } }, { external: true })
    }

    const newUsage = Array.isArray(rvusage) ? rvusage.join(' ') : rvusage

    if (newUsage !== bookingEstimateParams.value.rvUsage) {
      updateRvUsage(newUsage as BookingPriceRequest['RvUsage'])
    }
  }
})
