import type { AUTHENTICATION_INTENT, AUTHENTICATION_PROVIDERS } from '~/constants/authentication'

// Look into moving this to useAuthentication
export default function () {
  const authenticationModal = useAuthenticationModal()
  const authenticationType = useAuthenticationType()
  const authenticationIntent = useAuthenticationIntent()
  const { authenticationProvider } = useAuthentication()

  function showLogin(params?: {
    intent?: AUTHENTICATION_INTENT
    provider?: AUTHENTICATION_PROVIDERS // Only support email for now
  }) {
    const { intent, provider } = params ?? {}

    authenticationType.value = 'Login'

    authenticationIntent.value = intent

    authenticationProvider.value = provider

    authenticationModal.value = true
  }

  const isLoginVisible = computed(() => authenticationType.value === 'Login')

  return {
    isLoginVisible,
    showLogin,
  }
}
