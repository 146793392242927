<template>
  <select
    v-model="model"
    class="zform-select"
    :class="{ 'default-selected': model === '' }"
  >
    <option
      v-if="!noDefault"
      value=""
      disabled
      selected
    >
      {{ defaultLabel ? defaultLabel : t('defaultLabel') }}
    </option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :selected="option.value === model && noDefault"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  options: { value: string, label: string }[]
  noDefault?: boolean
  defaultLabel?: string
}>(), {
  defaultLabel: '',
})

const model = defineModel<string>()

const { t } = useI18n()
</script>

<i18n lang="json">
{
  "en": {
    "defaultLabel": "Select"
  },
  "fr": {
    "defaultLabel": "Sélectionner"
  }
}
</i18n>
