<template>
  <div
    :class="wrapperClasses"
  >
    <div
      v-if="hasTriggerContent"
      :class="triggerClasses"
      @click="toggle"
    >
      <div><slot name="trigger" /></div>
      <Fa
        v-if="!isTriggerDisabled"
        :icon="['fas', 'chevron-down']"
        :class="iconClasses"
      />
    </div>
    <div
      ref="collapse"
      :class="collapseContentClasses"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { COLLAPSE_GROUP_INJECTION_KEY } from './constants'
import type { CollapseGroupInjectionPayload } from '~/types/style-guide'

const props = defineProps<{
  visible?: boolean
  disableTrigger?: boolean
  fullWidth?: boolean
  padded?: boolean
  contentClass?: string
  hideBorder?: boolean
}>()

const id = useId()
const { accordion, openCollapseId, updateOpenCollapseId } = inject<CollapseGroupInjectionPayload>(COLLAPSE_GROUP_INJECTION_KEY, {
  accordion: false,
  openCollapseId: ref(''),
  updateOpenCollapseId: (_: string) => { },
})

const showCollapseContent = ref<boolean>(props.visible)

const slots = useSlots()
const hasTriggerContent = computed(() => Boolean(slots.trigger))

const isTriggerDisabled = computed(() => !hasTriggerContent.value || props.disableTrigger)

const isCollapseOpen = computed(() => {
  if (accordion) {
    return openCollapseId?.value === id
  }
  // if no trigger, rely on visible prop
  return isTriggerDisabled.value ? props.visible : showCollapseContent.value
})

function toggle() {
  if (accordion) {
    updateOpenCollapseId(id)
  }

  showCollapseContent.value = !showCollapseContent.value
}

const wrapperClasses = computed(() => [
  {
    'zcollapse': !props.hideBorder,
    'zcollapse-fullwidth': props.fullWidth,
    'zcollapse-open': isCollapseOpen.value,
    'zcollapse-padded': props.padded,
  },
])

const triggerClasses = computed(() => [
  'zcollapse-trigger',
  {
    'zcollapse-trigger-disabled': isTriggerDisabled.value,
  },
])

const iconClasses = computed(() => [
  'transition-transform',
  {
    'rotate-180': isCollapseOpen.value,
  },
])

const collapseContentClasses = computed(() => [
  'zcollapse-content',
  props.contentClass,
  {
    'zcollapse-content-fullwidth': props.fullWidth,
    'zcollapse-content-notrigger': isTriggerDisabled.value,
  },
])
</script>
