import type { LocationQueryRaw } from 'vue-router'
import { ROUTE_BASE_NAME } from '~/constants'

export default function useAbsoluteRvUrl(slug: string, query?: string) {
  const runtimeConfig = useRuntimeConfig()
  const localePath = useLocalePath()

  const path = {
    name: ROUTE_BASE_NAME.rv_rental,
    params: { alias: slug },
    query,
  } as unknown as LocationQueryRaw

  return `${runtimeConfig.public.baseURL}${localePath(path)}`
}
