<template>
  <ZNavbarNav class="ml-auto">
    <template v-if="isLoggedIn">
      <ZNavItem
        :to="localePath('/dashboard')"
        data-testid="my-rvezy"
      >
        {{ t('pageLink.myRvezy') }}
        <span
          v-if="dashboardBadge"
          class="push-note"
        />
      </ZNavItem>
      <ZNavItem
        :to="localePath('/dashboard/favourites')"
        no-prefetch
      >
        {{ t('pageLink.favourites') }}
      </ZNavItem>
      <ZNavItem
        data-testid="inbox"
        :to="localePath('/dashboard/messages')"
      >
        {{ t('pageLink.inbox') }}
        <span
          v-if="inboxBadge"
          class="push-note"
        />
      </ZNavItem>
      <ZNavItem
        :href="t('helpCenter.url')"
        @click="handleTrackHelpCenterClicked"
      >
        {{ t('helpCenter.short') }}
      </ZNavItem>
      <ZNavItem
        data-testid="list-an-rv"
        :to="localePath('dashboard-rvs-new')"
        no-prefetch
      >
        {{ t('listAnRV') }}
      </ZNavItem>
      <li>
        <ButtonBuyAnRv
          use-outline
          :text-btn="t('buyAnRv')"
          cta="header"
        />
      </li>
    </template>
    <template v-else>
      <ZNavItem
        data-testid="sign-up"
        :href="localePath('index-signup')"
        external
        @click.prevent="showSignup"
      >
        {{ t('signUp') }}
      </ZNavItem>
      <ZNavItem
        data-testid="login"
        :href="localePath('index-signin')"
        external
        @click.prevent="showLogin"
      >
        {{ t('login') }}
      </ZNavItem>
      <ZNavItem
        :href="t('helpCenter.url')"
        @click="handleTrackHelpCenterClicked"
      >
        {{ t('helpCenter.short') }}
      </ZNavItem>
      <ZNavItem
        :to="linkListRv"
        no-prefetch
      >
        {{ t('listAnRV') }}
      </ZNavItem>
      <li>
        <ButtonBuyAnRv
          use-outline
          :text-btn="t('buyAnRv')"
          cta="header"
        />
      </li>
    </template>

    <AppHeaderNavAvatarDropdown v-if="isLoggedIn" />
  </ZNavbarNav>
</template>

<script setup lang="ts">
import { trackHelpCenterClicked } from '~/lib/tracking'

withDefaults(defineProps<{
  inboxBadge?: number
  dashboardBadge?: boolean
}>(), {
  inboxBadge: 0,
})

const { showLogin } = useLogin()
const { showSignup } = useSignup()
const { linkListRv } = useOwnerCommon()
const { isLoggedIn } = useAuthentication()
const { t } = useI18n()
const { routeBaseName } = useBaseName()
const localePath = useLocalePath()

function handleTrackHelpCenterClicked() {
  trackHelpCenterClicked({
    pageSource: routeBaseName.value,
  })
}
</script>

<style lang="scss" scoped>
.znav-item {
  :deep(.list-rv) {
    text-transform: none;
    min-width: 7.25rem;
    text-align: center;

    &:hover {
      color: getColor('primary-500') !important;
    }

    &:focus {
      color: getColor('primary-500');
    }
  }
}
</style>

<i18n src="~/locales/common/app.json" lang="json" />
