<template>
  <div
    class="disclaimer"
  >
    <span class="title">{{ t('title') }}</span>
    {{ t('subtitle') }}
    <ZTooltipIcon
      :content="t('tooltip')"
      :title="t('title')"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
</script>

<style lang="scss" scoped>
.disclaimer {
  color: getColor('primary-350');
  @include caption;

  .title {
    display: block;
    @include caption-strong;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "Insurance and protection",
    "subtitle": "Insurance provided by Mobilitas.",
    "tooltip": "All plans include roadside assistance and $1,000,000 in liability coverage provided by Mobilitas Insurance, or Apollo."
  },
  "fr": {
    "title": "Assurance et protection",
    "subtitle": "Assurance fournie par Mobilitas",
    "tooltip": "Tous les plans incluent une assistance routière et une couverture de responsabilité de 1 000 000 $ fournie par Mobilitas Insurance ou Apollo."
  }
}
</i18n>
