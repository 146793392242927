<template>
  <component
    :is="tag"
    :to="to"
    :href="href"
    :class="computedClasses"
    :type="tag === 'button' ? type : undefined"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

import type { ButtonSize, ButtonType, ButtonVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  to?: string
  href?: string
  variant?: ButtonVariant
  size?: ButtonSize
  block?: boolean
  outline?: boolean
  outlineThin?: boolean
  icon?: boolean
  label?: boolean
  link?: boolean
  type?: ButtonType
}>(), {
  size: 'md',
  type: 'button',
})

const tag = computed(() => (props.href ? 'a' : props.to ? NuxtLink : 'button'))

const buttonClasses = computed(() => [
  'zbtn',
  `zbtn-${props.variant || 'primary'}${props.outlineThin || props.outline ? '-outline' : ''}`,
  `zbtn-${props.size}${props.icon ? '-icon' : ''}`,
  {
    'zbtn-outline-thin': props.outlineThin,
    'zbtn-block': props.block,
  },
])

const buttonLabelClasses = computed(() => [
  'zbtn-label',
  `zbtn-label-${props.size}`,
  {
    'zbtn-label-block': props.block,
  },
])

const buttonLinkClasses = computed(() => [
  'zlink',
  `zlink-${props.size}`,
  `zlink-${props.variant || 'highlight'}`,
  {
    'zlink-block': props.block,
  },
])

const computedClasses = computed(() =>
  props.link ? buttonLinkClasses.value : props.label ? buttonLabelClasses.value : buttonClasses.value,
)
</script>
