<template>
  <div
    :class="computedClasses"
    aria-atomic
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isInvalid?: boolean
}>()

const computedClasses = computed(() => {
  return [
    'z-form-invalid-feedback',
    {
      'z-form-invalid-feedback-visible': props.isInvalid,
    },
  ]
})
</script>
