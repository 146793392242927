<template>
  <div class="zoom-buttons">
    <button
      type="button"
      :disabled="currentZoom >= maxZoom"
      @click="emit('zoom:in')"
    >
      +
    </button>
    <button
      type="button"
      :disabled="currentZoom <= minZoom"
      @click="emit('zoom:out')"
    >
      -
    </button>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  currentZoom: number
  minZoom: number
  maxZoom: number
}>()

const emit = defineEmits<{
  'zoom:in': []
  'zoom:out': []
}>()
</script>

<style lang="scss" scoped>
.zoom-buttons {
  display: flex;
  flex-direction: column;

  button {
    background: #fff;
    display: inline-block;
    border: 0;
    font-size: 2rem;
    line-height: 3.5rem;
    padding: 0;
    width: 3.5rem;
    margin: 0.0625rem;
    border-radius: 0.5rem;

    &:not([disabled]) {
      color: getColor('primary-500');
    }

    & + button {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 0.0625rem;
        left: 0;
        right: 0;
        background-color: getColor('primary-100');
      }
    }
  }
}
</style>
