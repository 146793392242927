<template>
  <div class="layout-default">
    <LazyAppMobileAppDownloadBanner
      v-if="showBanner"
      @banner-dismissed="dismissClicked"
    />
    <LazyAppMasquerade v-if="isMasquerading" />

    <slot
      v-if="!layoutParams.hideHeader"
      name="pre-header"
    />
    <AppHeader
      v-if="!layoutParams?.hideHeader"
      :hide-navigation="layoutParams.hideNavigation"
    />

    <slot />
    <template v-if="!layoutParams?.hideFooter">
      <AppFooter
        :is-sticky="isRvSearchPage"
        :is-host-variant="layoutParams.hasHostFooter"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
const { isMasquerading } = useMasquerade()
const { showAppBanner } = useAppBanner()
const { isRvSearchPage } = useBaseName()
const { layoutParams } = useLayout()

const showBanner = ref(showAppBanner.value)
function dismissClicked() {
  showBanner.value = false
}

await useScripts()
</script>
