<template>
  <ZButton
    :variant="iconButton ? 'white' : undefined"
    :outline="!iconButton"
    :icon="iconButton"
    href="#"
    tabindex="0"
    :link="link"
    :class="{ 'bigger-icon': biggerIcon }"
    v-bind="$attrs"
    @click="handleClick"
  >
    <Fa
      v-if="!link"
      :icon="['far', 'share-nodes']"
    /><span
      v-if="!noLabel"
      :class="['ml-2', { 'link-behaviour': iconButton }]"
    >{{ btnText ? btnText : showTextPromote ? t('promote') : t('actions.share') }}</span>
  </ZButton>
  <ZPopover
    v-if="!isWebView"
    ref="sharePopoverEl"
    placement="bottom"
  >
    <template #default>
      <AppSocialShare
        :page="page"
        :url="url"
        :show-items-vertical="showItemsVertical"
      />
    </template>
  </ZPopover>
</template>

<script setup lang="ts">
import type { ComponentInstance } from 'vue'
import type { ZPopover } from '#components'
import { COOKIES } from '~/constants'
import postMessageToMobileApp from '~/lib/postMessageToMobileApp'

const props = withDefaults(
  defineProps<{
    iconButton?: boolean
    noLabel?: boolean
    showItemsVertical?: boolean
    biggerIcon?: boolean
    page: string
    endUrl?: string
    showTextPromote?: boolean
    link?: boolean
    btnText?: string
  }>(),
  {
    endUrl: '',
    btnText: '',
  },
)

defineOptions({
  inheritAttrs: false,
})

const { t } = useI18n()

const sharePopoverEl = ref<ComponentInstance<typeof ZPopover>>()

const sessionIdCookie = useCookie(COOKIES.session)
const runtimeConfig = useRuntimeConfig()
const route = useRoute()
const { isWebView } = usePlatform()

const url = computed(() => {
  return props.endUrl !== ''
    ? `${runtimeConfig.public.baseURL}${props.endUrl}`
    : `${runtimeConfig.public.baseURL}${route.path}?rts=${sessionIdCookie.value}`
})

/**
 * Handles clicking the share button.
 *
 * When the user is using a webview (from the app), we post a message to the app
 * instead.
 */
function handleClick(event: MouseEvent) {
  if (!isWebView.value) {
    event.preventDefault()
    sharePopoverEl.value?.toggleShow(event.currentTarget as HTMLElement)
    return
  }

  postMessageToMobileApp({
    android: { func: 'shareUrl', params: [window.location.href] },
    ios: {
      command: 'shareUrl',
      parameters: {
        url: window.location.href,
      },
    },
  })
}
</script>

<style lang="scss" scoped>
.bigger-icon {
  font-size: 1.5rem;
}
.link-behaviour {
  &:hover {
    text-decoration: underline;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "promote": "Promote"
  },
  "fr": {
    "promote": "Promouvoir"
  }
}
</i18n>
