<template>
  <span
    v-if="amount"
    class="rating-reviews"
    @click="emit('click')"
  >
    <ZRating
      v-if="rating"
      :value="formatWithPrecision(rating)"
      :icon-variant="iconVariant"
      class="mr-2"
    /><span
      class="reviews-count-wrapper"
    >(<span class="review-count">{{ amount }}<span class="review-label">&nbsp;{{ t('quantityReviews', amount) }}</span></span>)</span>
  </span>
</template>

<script setup lang="ts">
import type { RatingIconVariant } from '~/types/style-guide'

defineProps<{
  amount: number
  rating: number
  iconVariant?: RatingIconVariant
}>()

const emit = defineEmits<{
  click: []
}>()

const { t } = useI18n({
  useScope: 'local',
})
</script>

<style lang="scss" scoped>
.rating-reviews {
  cursor: pointer;
}

.reviews-count-wrapper {
  color: getColor('primary-350');
}
</style>

<i18n lang="json">
{
  "en": {
    "quantityReviews": "reviews | review | reviews"
  },
  "fr": {
    "quantityReviews": "avis"
  }
}
</i18n>
