import { PromocodeErrors } from '~/lib/enums'
import type { NuxtError } from '#app'
import type { Nullable } from '~/types'
import type { BackendErrorInterface } from '~/types/backend-api-errors'

export default function usePromoCode() {
  const { t } = useI18n()
  const promoCodePending = usePromoCodePending()
  const promoCodeError = usePromoCodeError()
  const promoCodeData = usePromoCodeData()

  const { $captureError, $rentals } = useNuxtApp()
  async function updatePromoCode(bookingId?: number, code: Nullable<string> = null) {
    if (!bookingId || promoCodePending.value) {
      return
    }

    try {
      promoCodePending.value = true

      // Validate empty promo code.
      if (typeof code === 'string' && !code.trim()) {
        throw createError({
          data: {
            Code: 'INVALID_PROMO_CODE',
            Message: 'Promo code is required',
          },
        })
      }

      await $rentals('/api/bookings/{bookingId}/add-promotion-payment', {
        method: 'post',
        path: {
          bookingId,
        },
        body: {
          Code: code ? code.toUpperCase() : null,
        },
      })

      promoCodeData.value = code
      clearPromoCodeError()
    }
    catch (error) {
      promoCodeError.value = createError(error as NuxtError)
      $captureError(error)
    }
    finally {
      promoCodePending.value = false
    }
  }

  const promoCodeErrorMessage = computed(() => {
    const errData = promoCodeError.value?.data as BackendErrorInterface

    if (!errData) return undefined

    switch (errData?.Code) {
      case 'INVALID_PROMO_CODE':
        return t('errors.required')

      case PromocodeErrors.PROMOTION_BOOKING_REQUIREMENT:
        return t('pages.bookingPayment.promoCode.noQualify')

      case PromocodeErrors.PROMOTIONCODE_NOT_EXIST:
        return t('pages.bookingPayment.promoCode.invalid')

      case PromocodeErrors.PROMOTION_NOT_ACTIVE:
        return t('pages.bookingPayment.promoCode.inactive')

      case PromocodeErrors.PROMOTION_LIMIT_USER:
        return t('pages.bookingPayment.promoCode.maximumUses')

      case PromocodeErrors.PROMOTION_LIMIT_TOTAL_QUANTITY:
        return t('pages.bookingPayment.promoCode.promotionLimitTotalQuantity')

      case PromocodeErrors.PROMOTION_WRONG_COUNTRY:
        return t('pages.bookingPayment.promoCode.wrongCountry')

      default:
        return errData?.Message || t('pages.bookingPayment.unknownError')
    }
  })

  function clearPromoCodeError() {
    promoCodeError.value = null
  }

  return {
    promoCodeData,
    promoCodeError,
    promoCodeErrorMessage,
    promoCodePending,
    updatePromoCode,
    clearPromoCodeError,
  }
}
