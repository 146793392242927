<template>
  <div>
    <ClientOnly>
      <LazyBookingDownloadAppModalSmall
        v-if="isQuarterSheet || isHalfSheet"
        :is-quartersheet="isQuarterSheet"
        @close="close"
      />
      <LazyBookingDownloadAppModalFullSheet
        v-if="isFullSheet"
        :type="type"
        :page-source="pageSource"
        @close="close"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import type { DownloadAppModalVariants } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  type?: DownloadAppModalVariants
  pageSource?: string
}>(), {
  type: 'FullSheetGeneral',
})

const emit = defineEmits<{
  close: []
  hide: []
}>()

const isQuarterSheet = computed(() => props.type === 'QuarterSheet')
const isHalfSheet = computed(() => props.type === 'HalfSheet')
const isFullSheet = computed(() => props.type === 'FullSheetGeneral' || props.type === 'FullSheetFinishBooking')

function close() {
  emit('close')
  emit('hide')
}

const { isMobile } = useDevice()

onMounted(() => {
  if (!isMobile) {
    close()
  }
})
</script>
