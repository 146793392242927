<template>
  <div :class="computedClasses">
    <h4
      v-if="title"
      class="zcard-title"
    >
      {{ title }}
    </h4>
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  title?: string
  noPadding?: boolean
}>()

const computedClasses = computed(() => [
  'zcard-body',
  {
    'zcard-body-paddingless': props.noPadding,
  },
])
</script>
