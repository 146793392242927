<template>
  <div class="localization">
    <ZButton
      class="toggle-locale"
      @click="toggleLocale"
    >
      <Fa :icon="['fal', 'globe']" /><span>{{ currentLocale.name }}</span>
    </ZButton>
    <AppCurrencySelector
      v-if="!isLoggedIn"
      class="toggle-currency"
    />
  </div>
</template>

<script setup lang="ts">
const { locale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const currentLocale = computed(() => {
  return locales.value.filter((i) => i.code === locale.value)[0]
})

const alternateLocale = computed(() => {
  return locales.value.filter((i) => i.code !== locale.value)[0]
})

const { isLoggedIn } = useAuthentication()

async function toggleLocale() {
  await navigateTo(switchLocalePath(alternateLocale.value.code))
}
</script>

<style lang="scss" scoped>
.localization {
  display: flex;
  gap: 1.5rem;

  @include media-min-size(medium) {
    gap: 1rem;
  }

  @include media-min-size(xLarge) {
    gap: 3rem;
  }

  .toggle-locale,
  .toggle-currency {
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: getColor('primary-500');

    padding-left: 0;
    padding-right: 0;

    border-radius: 0;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .toggle-locale:hover,
  .toggle-currency:hover {
    text-decoration: underline;
  }
}
</style>
