<template>
  <ZModal
    :visible="authenticationModalShown"
    padded
    hide-footer
    no-close-on-backdrop
    :title="modalTitle"
    @hide="handleHideModal"
    @hidden="handleHiddenModal"
  >
    <AppAuth @toggle-auth-form="toggleAuth" />
  </ZModal>
</template>

<script setup lang="ts">
import { AuthProviders as signupTypes } from '~/lib/auth'
import type { SignupExitedParamsResponseEnum } from '~/types/tracking'

const { t } = useI18n()
const { routeBaseName } = useBaseName()
const localePath = useLocalePath()
const {
  authenticationIntent,
  isSignupVisible,
  showSignup,
  enquiryOwnerName,
} = useSignup()
const { isLoginVisible, showLogin } = useLogin()

const modalTitle = computed(() => {
  switch (authenticationIntent.value) {
    case 'BookingIntent':
      return t('auth.bookingIntentTitle')

    case 'EnquiryIntent':
      return t('auth.enquiryIntentTitle')

    case 'ListingIntent':
      return t('auth.listYourRV')

    default:
      if (isSignupVisible.value) {
        return t('auth.createAnRVezyAccount')
      }
      return t('auth.loginToRVezy')
  }
})

const { hideAuthModal, authenticationModalShown, authenticationType, authenticationProvider, isLoggedIn } = useAuthentication()

watch(
  authenticationType,
  (toVal) => {
    authenticationModalShown.value = Boolean(toVal)
  },
  { immediate: true },
)

const { isOwnerPage } = useBaseName()
const isOwner = computed(() => {
  return authenticationIntent.value === 'ListingIntent' || isOwnerPage.value
})

const { trackSignupExited, trackSignupViewed } = useTracking()
function trackSignupExitedEvent({ trigger, provider }: { trigger: SignupExitedParamsResponseEnum, provider: 'email' | undefined }) {
  trackSignupExited({
    isOwner: isOwner.value,
    response: trigger,
    signupType: provider,
  })
}

watch(
  isSignupVisible,
  (newVal, oldVal) => {
    if (newVal) {
      trackSignupViewed({
        signupTypes,
        isOwner: isOwner.value,
        cta: routeBaseName.value,
        pageSource: routeBaseName.value,
      })
    }
    else if (isLoginVisible.value && oldVal !== undefined) {
      trackSignupExitedEvent({
        trigger: 'login',
        provider: authenticationProvider.value ? 'email' : undefined,
      })
    }
  },
  {
    immediate: true,
  },
)

function handleHideModal({ trigger }: { trigger: SignupExitedParamsResponseEnum }) {
  if (isSignupVisible.value) {
    trackSignupExitedEvent({
      trigger,
      provider: authenticationProvider.value ? 'email' : undefined,
    })
  }
}

function closeModal() {
  authenticationModalShown.value = false
  authenticationType.value = null

  // Update route if user is on signin route or signup route
  if (routeBaseName.value && ['index-signin', 'index-signup'].includes(routeBaseName.value)) {
    navigateTo(localePath('index'))
  }
}

function toggleAuth() {
  if (authenticationType.value === 'Login') {
    showSignup({ intent: authenticationIntent.value, ownerName: enquiryOwnerName.value })
  }
  else if (authenticationType.value === 'Signup') {
    showLogin({ intent: authenticationIntent.value })
  }
}

onBeforeUnmount(() => {
  if (authenticationModalShown.value && !isLoggedIn.value) {
    handleHideModal({ trigger: 'navigation' })
  }
})

function handleHiddenModal() {
  hideAuthModal()
  closeModal()
}
</script>

<i18n src="~/locales/common/auth.json" lang="json" />
