export default function useInternationalPricing() {
  const { isWebView } = usePlatform()
  const { isLoggedIn } = useAuthentication()

  /**
   * International pricing is only shown to non-logged in users and non-webview users.
   */
  const canShowInternationalPricing = computed(() => !isWebView.value && !isLoggedIn.value)

  return {
    canShowInternationalPricing,
  }
}
