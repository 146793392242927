<template>
  <span
    ref="triggerEl"
    v-bind="$attrs"
    @click="toggleClick"
    @mouseenter="enableVisibility"
    @mouseleave="disableVisibility"
  >
    <slot />
  </span>

  <ClientOnly>
    <OverlayDrawer
      v-if="isMobile"
      :visible="visible"
      :title="title"
      @close="visible = false"
    >
      <slot name="content">
        {{ content }}
      </slot>

      <div class="mt-4">
        <ZButton
          block
          @click="visible = false"
        >
          {{ buttonText || t('actions.gotIt') }}
        </ZButton>
      </div>
    </OverlayDrawer>

    <div
      v-else-if="visible"
      ref="floatingEl"
      :class="computedClasses"
      :style="floatingStyles"
    >
      <slot name="content">
        {{ content }}
      </slot>

      <div
        ref="floatingArrowEl"
        :style="arrowComputedStyles"
        class="ztooltip-arrow"
      />
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { arrow, autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue'
import type { TooltipPlacement } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  content?: string
  title: string
  buttonText?: string
  placement?: TooltipPlacement
}>(), {
  content: '', // TODO: Add eslint rules to enforce if content is passed through the slot
  buttonText: '',
  placement: 'bottom',
})

defineOptions({
  inheritAttrs: false,
})

const { t } = useI18n()

const computedClasses = computed(() => ([
  'ztooltip',
  `ztooltip-${middlewareData.value.offset?.placement ?? props.placement}`,
]))

const { isMobile } = useDevice()
const visible = ref(false)

const triggerEl = ref<HTMLSpanElement>()
const floatingEl = ref<HTMLDivElement>()
const floatingArrowEl = ref<HTMLDivElement>()
const { floatingStyles, middlewareData } = useFloating(triggerEl, floatingEl, {
  placement: props.placement,
  middleware: [offset(8), flip(), shift({ padding: 8 }), arrow({ element: floatingArrowEl, padding: 8 })],
  whileElementsMounted: autoUpdate,
})

const arrowComputedStyles = computed(() => ({
  left: middlewareData.value.arrow?.x != null ? `${middlewareData.value.arrow.x}px` : '',
  top: middlewareData.value.arrow?.y != null ? `${middlewareData.value.arrow.y}px` : '',
}))

function disableVisibility() {
  if (!isMobile) {
    visible.value = false
  }
}

function enableVisibility() {
  if (!isMobile) {
    visible.value = true
  }
}

function toggleClick() {
  if (isMobile) {
    visible.value = !visible.value
  }
}
</script>
