import { toRefs } from '@vueuse/core'
import type { FinancialCommon, Nullable, Plan } from '~/types'

export default function useFinancialData(data?: Ref<Nullable<FinancialCommon> | undefined>) {
  const financialData = computed(() => getFinancial(data?.value))

  function getProtectionPlans(isValidForStationaryPlan: boolean): Plan[] {
    const {
      financialTotalNights,
      financialNightlyInsuranceRateStationary,
      financialNightlyRoadsideStationaryRate,
      financialNightlyInsuranceRateBasic,
      financialNightlyRoadsideBasicRate,
      financialNightlyInsuranceRateStandard,
      financialNightlyRoadsideStandardRate,
      financialNightlyInsuranceRatePremium,
      financialNightlyRoadsidePremiumRate,
      financialDeductibleAmountStationary,
      financialDeductibleAmountBasic,
      financialDeductibleAmountStandard,
      financialDeductibleAmountPremium,
    } = financialData.value

    const pricePerNightStationary = financialNightlyInsuranceRateStationary + financialNightlyRoadsideStationaryRate
    const pricePerNightBasic = financialNightlyInsuranceRateBasic + financialNightlyRoadsideBasicRate
    const pricePerNightStandard = financialNightlyInsuranceRateStandard + financialNightlyRoadsideStandardRate
    const pricePerNightPremium = financialNightlyInsuranceRatePremium + financialNightlyRoadsidePremiumRate

    return isValidForStationaryPlan
      ? [{
          name: 'Stationary',
          pricePerNight: pricePerNightStationary,
          priceTotal: pricePerNightStationary * financialTotalNights,
          deductible: financialDeductibleAmountStationary,
        }]
      : [{
          name: 'Basic',
          pricePerNight: pricePerNightBasic,
          priceTotal: pricePerNightBasic * financialTotalNights,
          deductible: financialDeductibleAmountBasic,
        },
        {
          name: 'Standard',
          pricePerNight: pricePerNightStandard,
          priceTotal: pricePerNightStandard * financialTotalNights,
          deductible: financialDeductibleAmountStandard,
        },
        {
          name: 'Premium',
          pricePerNight: pricePerNightPremium,
          priceTotal: pricePerNightPremium * financialTotalNights,
          deductible: financialDeductibleAmountPremium,
        }]
  }

  return {
    ...toRefs(financialData),
    getProtectionPlans,
  }
}
