<template>
  <ZButton @click="toggleCurrency">
    <Fa :icon="['far', 'dollar']" /><span>{{ selectedCurrencyCode }}</span>
  </ZButton>
</template>

<script setup lang="ts">
import { CURRENCIES } from '~/constants'
import { trackCurrencyUpdated } from '~/lib/tracking'

const { routeBaseName } = useBaseName()

const { selectedCurrencyCode, updatePreferredCurrency } = useCurrency()

function toggleCurrency() {
  const newCurrency = selectedCurrencyCode.value === CURRENCIES.USD ? CURRENCIES.CAD : CURRENCIES.USD
  updatePreferredCurrency(newCurrency)

  trackCurrencyUpdated({
    currentPage: routeBaseName.value,
    previousCurrency: selectedCurrencyCode.value,
    updatedCurrency: newCurrency,
  })
}
</script>

<style lang="scss" scoped>
:deep(.zdropdown-toggle) {
  border-width: 1px;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;

  &.zdropdown-toggle-caret::after {
    margin-left: 1.5rem;
  }

  + .zdropdown-menu {
    min-width: 100%;

    .zdropdown-item {
      padding: 0.5rem 1rem;
    }
  }
}
</style>
