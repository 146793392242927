import type { LayoutKey } from '#build/types/layouts'

/**
 * This composable returns the layout type based on the platform cookie.
 */
export default function () {
  const layoutParams = useLayoutParams()
  const { isWebView } = usePlatform()

  function getLayout(layout: LayoutKey = 'default') {
    if (isWebView.value) {
      return 'blank'
    }

    return layout
  }

  return {
    layoutParams,
    getLayout,
  }
}
