<template>
  <component
    :is="tag"
    :to="to"
    class="zavatar"
    :class="[formattedVariant, formattedSize]"
  >
    <slot>
      <span
        v-if="hasImage"
        class="zavatar-image"
      >
        <img
          :src="fixedSrc && src ? src : imgURL"
          :alt="alt"
          loading="lazy"
        >
      </span>

      <span
        v-else
        class="zavatar-text"
      >
        {{ text }}
      </span>

      <slot name="badge" />
    </slot>
  </component>
</template>

<script setup lang="ts">
import type { AvatarSize, AvatarVariant } from '~/types/style-guide'
import type { Nullable } from '~/types'
import { NuxtLink } from '#components'
import { IMAGE_QUALITY, IMAGE_RESOLUTION } from '~/constants/image'

/**
 * Refactor candidate. This component should _not_ get the image URL, this
 * should be provided directly to the prop.
 */
const props = withDefaults(defineProps<{
  src?: Nullable<string>
  fixedSrc?: boolean
  text?: string
  size?: AvatarSize
  variant?: AvatarVariant
  alt?: string
  to?: string
}>(), {
  size: 'md',
  variant: 'primary',
})

const runtimeConfig = useRuntimeConfig()

const tag = computed(() => props.to ? NuxtLink : 'div')

const hasImage = computed(() => Boolean(props.src))

const { getImageUrl } = useImageUrl()
const imgURL = computed(() => {
  // Default image if no avatar or initials have been passed in.
  if (!hasImage.value) {
    return `${runtimeConfig.public.staticAssetsURL}/web-app/default.png`
  }
  else if (!props.src && props.text) {
    return undefined
  }

  return getImageUrl({
    path: props.src,
    quality: IMAGE_QUALITY.none,
    resolution: IMAGE_RESOLUTION.medium,
  })
})

const formattedVariant = computed(() => {
  // background should be transparent if an image is set.
  if (hasImage.value) {
    return 'zavatar-transparent'
  }

  return `zavatar-${props.variant}`
})

const formattedSize = computed(() => {
  return `zavatar-${props.size}`
})
</script>
