<template>
  <ZTipbox
    v-if="errorMessage"
    variant="danger"
  >
    {{ errorMessage }}
  </ZTipbox>
</template>

<script setup lang="ts">
import type { BackendErrorInterface } from '~/types/backend-api-errors'

const { t } = useI18n()

const { bookingEstimateParams, bookingEstimateError } = useBookingEstimate()
const { rvGuests, rvMinimumTripLength, rvAllowPets } = useRvDetails()

const errorMessage = computed(() => {
  const errData = bookingEstimateError.value?.data as BackendErrorInterface

  if (!errData) return null
  const errorCode = errData?.Code

  switch (errorCode) {
    case 'BOOKING_OVERLAPS_DATES':
      // Sometimes we return `rangeConflict` instead of `unavailable`
      // EX: Ask owner a question
      // Perhaps we can standardize
      // return t('error.rangeConflict')
      return t('error.unavailable')

      // Doesn't exist in the backend
      // case 'SET_RV_SOLD_ERROR'

    case 'BOOKING_LEADTIME':
      return t('error.bookingLeadTimeError')

    case 'INVALID_DATE_CHECKIN':
      return t('error.bookingCheckInError', {
        day: formatAsDayOfWeek(bookingEstimateParams.value.dateStart),
      })

    case 'INVALID_DATE_CHECKOUT':
      return t('error.bookingCheckOutError', {
        day: formatAsDayOfWeek(bookingEstimateParams.value.dateEnd),
      })

      // The backend returns no error code for Cross Border Travel
      // case 'CROSS_BORDER_TRAVEL_DISALLOWED':
      //   return t('error.crossBorderTravel')

    case 'DELIVERY_DISTANCE_EXCEEDS_MAX':
      return t('error.deliveryDistanceExceedsMax')

    case 'NO_DELIVERY_DISTANCE_FOUND':
      return t('error.noDeliveryDistanceFound')

    case 'INVALID_GUEST_COUNT':
      if (!rvGuests.value) {
        return
      }
      return t('error.guestsMax', {
        max_number_of_guests: rvGuests.value,
      })

    case 'INVALID_TRIP_DURATION':
      if (!rvMinimumTripLength.value) {
        return
      }
      return t('error.rangeMin', {
        min_number_of_nights: rvMinimumTripLength.value,
      })

    case 'NO_PETS_ALLOWED':
      if (rvAllowPets.value) {
        return
      }
      return t('error.noPetsAllowed')

    default:
      return t('error.unknown')
  }
})
</script>

<i18n lang="json">
{
  "en": {
    "error": {
      "unknown": "An unknown error occurred.",
      "unavailable": "This RV is unavailable on the dates selected. Select different dates to try again.",
      "bookingTime": "Oh no! It's too late to book this RV for today.",
      "rangeConflict": "This RV has bookings on the days you have requested.",
      "rvSold": "This RV is unavailable for the dates you have selected.",
      "bookingLeadTimeError": "This RV is unavailable for the dates you have selected.",
      "rangeMin": "This RV has a minimum of {min_number_of_nights} nights.",
      "guestsMax": "This RV has a maximum of {max_number_of_guests} guests.",
      "bookingCheckInError": "This RV is unavailable for check in on {day}. Please choose another check in date.",
      "bookingCheckOutError": "This RV is unavailable for check out on {day}. Please choose another check out date.",
      "deliveryDistanceExceedsMax": "The address entered is outside the delivery range for this RV.",
      "noDeliveryDistanceFound": "The RV cannot be delivered to this address.",
      "noPetsAllowed": "No pets allowed"
    }
  },
  "fr": {
    "error": {
      "unknown": "Une erreur inconnue s’est produite.",
      "unavailable": "Ce VR n’est pas disponible pour les dates sélectionnées. Choisissez des dates différentes et réessayez.",
      "bookingTime": "Oh non! Il est trop tard pour réserver ce VR pour aujourd’hui.",
      "rangeConflict": "Ce VR a des réservations les jours que vous avez demandés.",
      "rvSold": "Ce VR n’est pas disponible pour les dates que vous avez sélectionnées.",
      "bookingLeadTimeError": "Ce VR n’est pas disponible pour les dates que vous avez sélectionnées.",
      "rangeMin": "Ce VR a un minimum de {min_number_of_nights} nuits.",
      "guestsMax": "Ce VR a un maximum de {max_number_of_guests} invités.",
      "bookingCheckInError": "Ce VR n’est pas disponible pour un départ le {day}. Veuillez sélectioner une autre date de départ.",
      "bookingCheckOutError": "Ce VR n’est pas disponible pour un retour le {day}. Veuillez sélectioner une autre date de retour.",
      "deliveryDistanceExceedsMax": "L’adresse ajoutée est en dehors de la plage de livraison pour ce VR.",
      "noDeliveryDistanceFound": "Le VR ne peut pas être livré à cette adresse.",
      "noPetsAllowed": "Pas d’animaux autorisés"
    }
  }
}
</i18n>
