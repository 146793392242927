<template>
  <Fa
    :class="`${variant}-circle-icon`"
    :icon="['fas', icon]"
  />
</template>

<script setup lang="ts">
import type { CircleIconVariants } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  variant?: CircleIconVariants
}>(), {
  variant: 'highlight',
})

const icon = computed<string>(() => {
  switch (props.variant) {
    case 'success':
      return 'circle-check'
    case 'danger':
      return 'circle-exclamation'
    case 'warning':
      return 'triangle-exclamation'
    default:
      return 'circle'
  }
})
</script>

<style lang="scss" scoped>
.success-circle-icon {
  color: getColor('success-700');
}

.danger-circle-icon {
  color: getColor('danger-500');
}

.warning-circle-icon {
  color: getColor('warning-500');
}

.highlight-circle-icon {
  color: getColor('highlight-500');
}
</style>
