<template>
  <div :class="computedClasses">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  noGutters?: boolean
}>()

const computedClasses = computed(() => [
  'zgrid',
  {
    'zgrid-no-gutters': props.noGutters,
  },
])
</script>
