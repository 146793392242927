<template>
  <div class="koa-campgrounds">
    <h3 class="mb-4 koa-header">
      <span>{{ t('closestKOACampgrounds') }}</span>
      <ZButton
        link
        variant="primary"
        :to="campgroundLink"
        target="_blank"
      >
        {{ t('viewAllCampgrounds') }}
      </ZButton>
    </h3>
    <GridList
      :results-length="data?.campgrounds?.length ?? 0"
      :page-size="quantity"
      :rows="1"
    >
      <template #default="{ visibleResults }">
        <CampgroundsCard
          v-for="(card, index) in visibleResults"
          :key="card.pregen_id"
          :index="index"
          :distance-unit="rvMeasurementUnit"
          :campground="data?.campgrounds?.[index]"
          :is-loading="status === 'pending'"
          :show-delivery="rvHasDelivery && (data?.campgrounds?.[index]?.distance ?? 0) <= rvDeliveryMaxDistanceInKm"
        />
      </template>
    </GridList>
  </div>
</template>

<script setup lang="ts">
import type { CampgroundSearch, PlaceSearch } from '~/types/campground'

const props = withDefaults(defineProps<{
  quantity?: number
}>(), {
  quantity: 3,
})

const runtimeConfig = useRuntimeConfig()
const { locale, t } = useI18n()
const { getCampgroundsUrl } = useCampgroundsUrl()

const {
  rvLocation,
  rvMeasurementUnit,
  rvHasDelivery,
  rvDeliveryMaxDistanceInKm,
} = useRvDetails()

const { data, status } = await useAsyncData(async () => {
  const [campgroundsData, defaultPlaceData] = await Promise.all([
    $fetch(`${runtimeConfig.public.apps.campgrounds.apiURL}/Campground/Search`, {
      params: {
        PageSize: props.quantity,
        Latitude: rvLocation.value?.Latitude,
        Longitude: rvLocation.value?.Longitude,
        LanguageCode: locale.value,
        Brand: 'KOA',
      // Limit to cross-border campgrounds ?
      },
    }),
    $fetch(`${runtimeConfig.public.apps.places.apiURL}/Search`, {
      params: {
        PageSize: 1,
        Latitude: rvLocation.value?.Latitude,
        Longitude: rvLocation.value?.Longitude,
        Country: rvLocation.value?.Country,
        PlaceType: 'City',
        DistanceKm: 1000,
        LanguageCode: locale.value,
      },
    }),
  ])

  return {
    campgrounds: (campgroundsData as CampgroundSearch)?.results ?? [],
    defaultPlace: (defaultPlaceData as PlaceSearch)?.results?.[0],
  }
}, {
  lazy: true,
})

const campgroundLink = computed(() => getCampgroundsUrl(`destination/${data.value?.defaultPlace?.route ?? ''}`))
</script>

<style lang="scss" scoped>
.koa-campgrounds {
  :deep(.grid-list) {
    @include media-min-size(medium) {
      --columns: 3;
    }

    > * {
      margin-bottom: 0;
    }
  }

  .koa-header {
    a {
      display: block;
      margin-top: 0.5rem;
      padding: 0;
      white-space: nowrap;
    }

    @include media-min-size(medium) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      a {
        margin-top: 0;
      }
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "closestKOACampgrounds": "Nearby KOA campgrounds",
    "viewAllCampgrounds": "View campgrounds"
  },
  "fr": {
    "closestKOACampgrounds": "Campings KOA à proximité",
    "viewAllCampgrounds": "Voir campings"
  }
}
</i18n>
