import { trackAppBannerClicked, trackExternalLinkClicked } from '~/lib/tracking'
import getTrackingParameters from '~/lib/getTrackingParameters'
import { COOKIES } from '~/constants'
import { QueryStrings, RouteBaseNameEnum } from '~/lib/enums/index.js'

export default function useDownloadApp() {
  const { $search } = useNuxtApp()
  const { isAndroid, isIos, isMacOS } = useDevice()
  const runtimeConfig = useRuntimeConfig()
  const utmCookie = useCookie(COOKIES.utmParameters)
  const rtsessionid = useCookie(COOKIES.session)
  const { user } = useAuthentication()
  const { isOwner } = useUser()
  const router = useRouter()
  const route = useRoute()
  const { routeBaseName } = useBaseName()

  const playStoreUrl = computed(() => {
    const { utms } = getTrackingParameters(
      {
        utms: utmCookie.value,
        rtsessionid: rtsessionid.value,
      },
      true,
    )

    let playStoreUrl
      = runtimeConfig.public.playStoreUrl

    if (Object.keys(utms).length) {
      playStoreUrl += `?${new URLSearchParams(utms)}`
    }

    return playStoreUrl
  })

  const iTunesUrl = computed(() => {
    const { utms } = getTrackingParameters(
      {
        utms: utmCookie.value,
        rtsessionid: rtsessionid.value,
      },
      true,
    )

    let iTunesUrl = runtimeConfig.public.appStoreUrl

    if (Object.keys(utms).length) {
      iTunesUrl += `?${new URLSearchParams(utms)}`
    }

    return iTunesUrl
  })

  const trackAndroid = () => {
    trackExternalLinkClicked({
      externalLink: playStoreUrl.value,
      cta: 'Download the app',
      pageSource: 'hostdashboardpage',
      isOwner: user.value ? isOwner.value : null,
    })
  }

  const trackiOs = () => {
    trackExternalLinkClicked({
      externalLink: iTunesUrl.value,
      cta: 'Download the app',
      pageSource: 'hostdashboardpage',
      isOwner: user.value ? isOwner.value : null,
    })
  }

  const downloadApp = async (params?: { pageSource?: string, platform?: string }) => {
    const { pageSource = routeBaseName.value, platform } = params ?? {}

    if (platform === 'android') {
      trackAndroid()
      window.open(playStoreUrl.value)
      return
    }

    if (platform === 'ios') {
      trackiOs()
      window.open(iTunesUrl.value)
      return
    }

    const { utms } = getTrackingParameters(
      {
        utms: utmCookie.value,
        rtsessionid: rtsessionid.value,
      },
      true,
    )

    trackAppBannerClicked({
      pageSource: routeBaseName.value,
      os: isIos ? 'ios' : 'android',
      cta: 'bannerClicked',
    })

    if (isAndroid) {
      trackAndroid()
    }
    else if (isIos) {
      trackiOs()
    }

    if (rtsessionid.value !== null && rtsessionid.value !== 'null' && !import.meta.server) {
      const { protocol, url } = runtimeConfig.public.appsFlyer
      const appsFlyerLink = `${url}?deep_link_value=`
      let base = protocol

      let queryParams = new URLSearchParams([])
      const utmParams = new URLSearchParams(utms ?? [])

      // We also pass the full query string to the dynamic link endpoint, but
      // we have to make sure of the following: If there's NO SearchAddress
      // and NO bounding box, we need to manually add the SearchAddress from
      // the search plugin location.
      //
      // This should only happen on the search page and on a listing page.
      if (pageSource === RouteBaseNameEnum.RV_SEARCH || pageSource === RouteBaseNameEnum.RV_RENTAL) {
        const url = window.location.href
        const urlObj = new URL(url)
        queryParams = new URLSearchParams(urlObj.search)

        let hasSearchAddress = false
        let hasBoundingBox = false

        // Check if SearchAddress OR a bounding box is provided.
        for (const [key] of queryParams) {
          if (key === QueryStrings.searchAddress) {
            hasSearchAddress = true
          }
          if (
            key === QueryStrings.neLat
            || key === QueryStrings.neLng
            || key === QueryStrings.swLat
            || key === QueryStrings.swLng
          ) {
            hasBoundingBox = true
          }
        }

        // Set the search address if it's not provided, but only on the search page.
        if (!hasSearchAddress && !hasBoundingBox && pageSource === RouteBaseNameEnum.RV_SEARCH) {
          const searchAddress = $search?.parameters?.location?.fullName ?? ''
          queryParams.set('SearchAddress', searchAddress)
        }

        // We also set the `SearchLat` and `SearchLng` as parameters, because
        // those are the values needed for the unified search endpoint in case
        // of a search address.
        if (!hasBoundingBox && pageSource === RouteBaseNameEnum.RV_SEARCH) {
          const searchLat = $search?.parameters?.location?.center?.lat?.toString()
          const searchLng = $search?.parameters?.location?.center?.lng?.toString()

          if (searchLat && searchLng) {
            queryParams.set('SearchLat', searchLat)
            queryParams.set('SearchLng', searchLng)
          }
        }
      }

      // Append the path as the deep link URL.
      // Exception: `index`, which should not be appended.
      if (pageSource !== RouteBaseNameEnum.INDEX) {
        base += route.path.substring(1)
      }

      // Combine the provided query params with the optional utm params.
      for (const [key, value] of utmParams) {
        queryParams.append(key, value)
      }

      // Add the (dev) env to the params.
      queryParams.append('rvezy_env', runtimeConfig.public.environment.toLowerCase())

      // Create the final query string.
      const params = new URLSearchParams(queryParams).toString()

      const encodedParams = encodeURIComponent(`${base}?${params}`)
      const link = `${appsFlyerLink}${encodedParams}`

      window.location.href = link
    }
    else if (isAndroid) {
      window.location.href = playStoreUrl.value
    }
    else if (isMacOS) {
      window.location.href = iTunesUrl.value
    }
    else {
      router.push('/')
    }
  }

  return {
    playStoreUrl,
    iTunesUrl,
    trackAndroid,
    trackiOs,
    downloadApp,
  }
}
