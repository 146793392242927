<template>
  <div :class="computedClasses">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  flush?: boolean
}>()

const computedClasses = computed(() => [
  'zlist-group',
  {
    'zlist-group-flush': props.flush,
  },
])
</script>
