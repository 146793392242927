<template>
  <ZButton
    variant="white"
    icon
    :to="localePath({ name: 'rv-search', query: route.query })"
  >
    <Fa :icon="['fas', 'chevron-left']" />
  </ZButton>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const route = useRoute()
</script>
