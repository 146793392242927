<template>
  <span class="rv-tag">
    <Fa
      v-if="config.icon"
      :icon="['fal', (config.icon as string)]"
      class="mr-1"
    />
    {{ config.label }}
  </span>
</template>

<script setup lang="ts">
import { RvTags } from '@/lib/enums'

const props = defineProps<{
  tag: string
}>()

const { t } = useI18n()

const config = computed(() => {
  let label, icon

  switch (props.tag) {
    case RvTags.Featured:
      label = t('featured')
      break

    case RvTags.DeliveryOnly:
      label = t('deliveryonly')
      icon = 'truck-fast'
      break

    default:
      break
  }

  return { label, icon }
})
</script>

<style lang="scss" scoped>
.rv-tag {
  @include caption;

  border: none;
  color: getColor('primary-500');
  background-color: getColor('primary-50');
  padding: 0.2rem 0.5rem;
  font-size: 0.75rem;
}
</style>

<i18n lang="json">
{
  "en": {
    "featured": "Featured",
    "deliveryonly": "Delivery only"
  },
  "fr": {
    "featured": "En vedette",
    "deliveryonly": "Livraison seulement"
  }
}
</i18n>
