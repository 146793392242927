<template>
  <div
    class="no-results-found"
    data-testid="no-results-found"
  >
    <div class="no-results-found__content">
      <ZImage
        :src="`${runtimeConfig.public.staticAssetsURL}/web/images/no-results-found.svg`"
        alt=""
      />
      <p>{{ t('noResultsFoundTip') }}</p>
      <ZButton
        class="no-results-found-btn"
        outline
        @click="clearFilters"
      >
        {{ t('clearFilters') }}
      </ZButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  'clear-filters': []
}>()
const runtimeConfig = useRuntimeConfig()
const { t } = useI18n()

function clearFilters() {
  emit('clear-filters')
}
</script>

<style scoped lang="scss">
.no-results-found {
  &__content {
    margin-top: 2rem;
    text-align: center;

    @include media-max-size(medium) {
      .no-results-found-btn {
        width: 100%;
      }
    }

    h2 {
      margin-top: 1.25rem;
      margin-bottom: 1rem;
      color: getColor('primary-500');
      text-transform: capitalize;
    }

    p {
      @include body-1;
      color: getColor('primary-500');
      @include media-max-size(medium) {
        @include caption;
      }
    }
  }
}
</style>

<i18n src="~/locales/common/search/results.json" lang="json" />
